import React from "react";
import {
    Card, Col, Container,
    Row
} from "react-bootstrap";

import { connect } from 'react-redux';
import { loadingAction, seleccionarMesaAction } from "../../../redux/actions";
import { posService } from "../../../services";

import { AlertConfirm } from "../../../components/alert";

function FacturasScreen() {

    const [facturas, setFacturas] = React.useState(null);

    React.useEffect(() => {
        getFacturas();
    }, []);

    const getFacturas = async () => {
        const result = await posService.ObtenerFacturas();
        setFacturas(result);
    }

    const deleteFacturas = async (id) => {

        AlertConfirm('Está seguro de eliminar este cliente?', 'question', async (response) => {
            if (response.isConfirmed) {
                await posService.EliminarFacturas(id);
                getFacturas();
            }
        })
    }

    return (
        <Container fluid>
            <Row className="p-4">
                <Col lg={4}>
                    <h2>Listado de Facturas</h2>
                </Col>
            </Row>
            <Row>
                <Col lg={12}>
                    <Card>
                        <Card.Body>
                            <div className="table-responsive">
                                <table className="table table-striped table-hover">
                                    <thead>
                                        <tr>
                                            <th>No Factura</th>
                                            <th>Cliente</th>
                                            <th>Cantidad Items</th>
                                            <th>Subtotal</th>
                                            <th>Total</th>
                                            <th>Opciones</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {facturas && facturas.map((data, index) => {
                                            return (
                                                <tr key={index}>
                                                    <td>{data.noFactura}</td>
                                                    <td>{data.cliente.tipoDocumento} {data.cliente.numeroDocumento} {data.cliente.nombre} </td>
                                                    <td>{data.totalItems} </td>
                                                    <td>{data.subtotal}</td>
                                                    <td>{data.total}</td>
                                                    <td>
                                                        <button type="button" className="btn btn-danger" onClick={() => deleteFacturas(data.id)}>Eliminar</button>
                                                    </td>
                                                </tr>
                                            )
                                        })}
                                    </tbody>
                                </table>

                            </div>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Container>

    );

}

const mapStateToProps = (state) => {
    return {
        pos: state.pos,
        user: state.user,
        mesaSeleccionada: state.pos.mesaSeleccionada
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        seleccionarMesaActionProps: (data) => { dispatch(seleccionarMesaAction(data)) },
        loadingActionProps: (data) => { dispatch(loadingAction(data)) }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(FacturasScreen);
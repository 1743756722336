import types from '../types';
import { decode } from '../../utils/jwt';

const initialState = {
    token: null,
    data: null,
    isAuthenticated: false
}

const initialStateLoading = false;

const initialStatePos = {
    cajaAbierta: false,
    cajaId: null,
    mesaSeleccionada: null,
    mesaAtencionId: null
}

const initialUIState = {
    itemSeleccionado: 'pos',
    collapsed: false
}

export const uiReducer = (stateUI = initialUIState, action) => {

    switch(action.type){
        case types.collapseMenu : {
            return {...stateUI, collapsed: !stateUI.collapsed };
        }
        case types.selectMenu : {
            return {...stateUI, itemSeleccionado: action.payload };
        }
        default:
            return stateUI;
    }

}

export const userDataReducer = (state = initialState, action) => {

    switch(action.type){
        case types.login : {
            return {
                token: action.payload,
                data: decode(action.payload),
                isAuthenticated: true
            };
        }
        case types.logout : {
            return initialState;
        }
        default:
            return state;
    }

}

export const loadingReducer = (state = initialStateLoading, action) => {

    switch(action.type){
        case types.isLoading : {
            return action.payload;
        }
        default:
            return state;
    }
}

export const posReducer = (state = initialStatePos, action) => {

    switch(action.type){

        case types.seleccionarMesa : {
            return {
                ...state,
                mesaSeleccionada: action.payload.mesaSeleccionada,
                mesaAtencionId: action.payload.mesaAtencionId
            };
        }

        case types.abrirCaja : {
            return {
                ...state,
                cajaAbierta: action.payload.cajaAbierta,
                cajaId: action.payload.cajaId,
                };
        }

        case types.cerrarCaja : {
            return {
                ...state,
                cajaAbierta: false,
                cajaId: null,
                mesaSeleccionada: null,
                mesaAtencionId: null
            };
        }
        default:
            return state;
    }

}
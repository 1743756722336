import React from "react";
import {
    Card, Col, Container,
    Row
} from "react-bootstrap";

import { connect } from 'react-redux';
import { loadingAction, seleccionarMesaAction } from "../../redux/actions";
import { personasService } from "../../services";

import { AlertConfirm } from "../../components/alert";

function ClientesScreen(props) {

    const [clientes, setClientes] = React.useState(null);

    React.useEffect(() => {
        getClientes();
      }, []);

      const getClientes = async () => {
        const result = await personasService.ObtenerClientes();
        console.log("mostrando result", result)
        setClientes(result);
      }

      const deleteClientes = async (id) => {

        AlertConfirm('Está seguro de eliminar este cliente?', 'question', async (response)=>{
            if(response.isConfirmed){
                const result = await personasService.EliminarCliente(id);
                console.log("mostrando result", result)
                getClientes();
            }
        })
      }



      return(
        <Container fluid>
            <Row className="p-4">
                <Col lg={4}>
                    <h2>Listado de Clientes</h2>
                </Col>
                {/* <Col lg={8} className="text-end">
                    <Link className="btn btn-success" to={`/clientes/nuevo`} >Nuevo Cliente</Link>
                </Col> */}
            </Row>
            <Row>
                <Col lg={12}>
                    <Card>
                        <Card.Body>
                            <div className="table-responsive">
                                <table className="table table-striped table-hover">
                                <thead>
                                    <tr>
                                        <th>Documento</th>
                                        <th>Nombre</th>
                                        <th>Telefono</th>
                                        <th>Email</th>
                                        <th>Opciones</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {clientes && clientes.map((data,index)=>{
                                        return(
                                        <tr key={index}>
                                            <td>{data.tipoDocumento} {data. numeroDocumento}</td>
                                            <td>{data.nombre}</td>
                                            <td>{data.telefono}</td>
                                            <td>{data.email}</td>
                                            <td>
                                                <button type="button" className="btn btn-danger" onClick={()=>deleteClientes(data.id)}>X</button>
                                            </td>
                                        </tr>
                                        )
                                    })}
                                </tbody>
                                </table>
                            
                            </div>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Container>

      );

}

const mapStateToProps= (state) =>{
    return {
        pos: state.pos,
        user: state.user,
        mesaSeleccionada : state.pos.mesaSeleccionada
    }
  };
  
  const mapDispatchToProps= (dispatch) =>{
    return {
      seleccionarMesaActionProps: (data) => {dispatch(seleccionarMesaAction(data))},
      loadingActionProps: (data) => {dispatch(loadingAction(data))}
  }
  }
  
  export default connect(mapStateToProps,mapDispatchToProps)(ClientesScreen);
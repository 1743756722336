import {Navigate} from 'react-router-dom';
import { useSelector } from "react-redux";
import { RingSpinner } from 'react-spinner-overlay';


const PrivateRoute = ({ isAuthenticated,loading, children }) => {

  return isAuthenticated ? loading ? <RingSpinner loading={loading}/>  : children  : <Navigate to="/login" />;
  };

export default PrivateRoute;
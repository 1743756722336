
import React from "react";
import { connect } from 'react-redux';
import { loadingAction, seleccionarMesaAction } from "../../../redux/actions";
import { productosService } from "../../../services";

function InventarioProducto(props) {

    const { productoId } = props;

    const [inventario, setInventario] = React.useState(null);

    React.useEffect(() => {
        getInventarioByProductId();
    })

    const getInventarioByProductId = async () => {

        try {
            const result = await productosService.ObtenerInventarioById(productoId)
            setInventario(result);
        } catch (error) {
            console.log("error", error)
        }
    }

    return (
        <>
            {inventario &&
                <>

                    <div className="container">
                        <div className="row">
                            <div className="col-lg-4">
                                <div className="card bg-color-primary">
                                    <div className="card-body">
                                        <div className="card-title">Entradas</div>
                                        <span>{inventario.entradas}</span>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4">
                                <div className="card bg-color-secondary">
                                    <div className="card-body">
                                        <div className="card-title">Salidas</div>
                                        <span>{inventario.salidas}</span>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4">
                                <div className="card bg-color-primary">
                                    <div className="card-body">
                                        <div className="card-title">Inventario</div>
                                        <span>{inventario.inventario}</span>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-lg-12">
                                <div className="table-responsive">
                                    <table className="table">
                                        <thead>
                                            <tr>
                                                <th>Tipo de operacion</th>
                                                <th>Cantidad</th>
                                                <th>Observaciones</th>
                                                <th>Fecha</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {inventario && inventario.movimientos.length > 0 && inventario.movimientos.map((data, index) => {
                                                return (
                                                    <tr key={index}>
                                                        <td>{data.operacion.nombre} {data.operacion.nombre == 'Entrada' ? <i className="fa fa-arrow-down"></i> : <i className="fa fa-arrow-up"></i>}</td>
                                                        <td>{data.cantidad}</td>
                                                        <td>{data.observaciones}</td>
                                                        <td>{new Date(data.fechaCreacion).toLocaleString('es')}</td>
                                                    </tr>
                                                )
                                            })}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </>

            }

        </>
    )
}
const mapStateToProps = (state) => {
    return {
        pos: state.pos,
        user: state.user,
        mesaSeleccionada: state.pos.mesaSeleccionada
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        seleccionarMesaActionProps: (data) => { dispatch(seleccionarMesaAction(data)) },
        loadingActionProps: (data) => { dispatch(loadingAction(data)) }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(InventarioProducto);
import { fetch } from '../../utils/fetch';

const CrearCliente = async(payload) => {
    
  try {
      const { data } = await fetch('clientes', 'post', payload)
      const {token} = data;
      return token
    } catch (e) {
      return Promise.reject(e)
    }
}


const ObtenerClientes = async(payload) => {
    
  try {
      const { data } = await fetch('clientes', 'get')
      return data
    } catch (e) {
      return Promise.reject(e)
    }
}

const EliminarCliente = async(id) => {
    
  try {
      const { data } = await fetch('clientes/'+id, 'delete')
      return data
    } catch (e) {
      return Promise.reject(e)
    }
}

const ObtenerMeseros = async() => {
    
  try {
      const { data } = await fetch('auth/usuarios/role/mesero', 'get')
      return data
    } catch (e) {
      return Promise.reject(e)
    }
}

const CrearMesero = async(payload) => {
    
  try {
      const { data } = await fetch('auth/signup', 'post', payload)
      return data
    } catch (e) {
      return Promise.reject(e)
    }
}

export const personasService = {
    CrearCliente,
    ObtenerClientes,
    EliminarCliente,
    ObtenerMeseros,
    CrearMesero
}
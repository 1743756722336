import { Form, Formik } from "formik";
import React from "react";
import {
    Card, Col, Container,
    Row
} from "react-bootstrap";
import { connect } from 'react-redux';
import Swal from 'sweetalert2';
import { loadingAction, seleccionarMesaAction } from "../../../redux/actions";
import { personasService } from "../../../services";

function NuevoMeseroScreen(props) {
    const { user, loadingActionProps } = props;


    const initialValues = {
        nombreCompleto: "",
        role: "mesero",
        usuario: "",
        clave: "",
        tenantId: user.data.TenantId,
    }

    const submit = async (params, resetForm) => {
        loadingActionProps(true);
        const payload = params
        try {
            const result = await personasService.CrearMesero(payload);
            console.log("mostrando result", result)
            loadingActionProps(false);
            Swal.fire({ icon: 'success', title: 'Éxito: ', text: 'Mesero creado con éxito' })
            setTimeout(() => { resetForm(); }, 500)
        } catch (error) {
            Swal.fire({ icon: 'error', title: 'Ops...: ', text: 'Error al crear el mesero' })
            loadingActionProps(false);
        }

    }

    return (
        <Container fluid>
            <Row className="p-4">
                <Col lg={12}>
                    <h2>Nuevo Mesero</h2>
                </Col>
            </Row>
            <Row>
                <Col lg={12}>
                    <Card>
                        <Card.Body>
                            <Formik
                                initialValues={initialValues}
                                onSubmit={async (values, { resetForm }) => {
                                    submit(values, resetForm);

                                }}
                            >
                                {({ values, handleChange, handleBlur }) => (
                                    <Form>
                                        <Row>
                                            <Col>
                                                <div className="mb-3">
                                                    <label>Nombre Completo</label>
                                                    <input type="text" className="form-control" name="nombreCompleto" value={values.nombreCompleto} onChange={handleChange} onBlur={handleBlur} />
                                                </div>
                                            </Col>
                                            <Col>
                                                <div className="mb-3">
                                                    <label>Role</label>
                                                    <select className="form-control" name="role" onChange={handleChange} value={values.role}>
                                                        <option value="">Seleccionar</option>
                                                        <option value="mesero">Mesero</option>
                                                    </select>
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <div className="mb-3">
                                                    <label>Usuario</label>
                                                    <input type="text" className="form-control" name="usuario" value={values.usuario} onChange={handleChange} onBlur={handleBlur} />
                                                </div>
                                            </Col>
                                            <Col>
                                                <div className="mb-3">
                                                    <label>Clave</label>
                                                    <input type="text" className="form-control" name="clave" value={values.clave} onChange={handleChange} onBlur={handleBlur} />
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <button type="submit" className="btn btn-primary">Guardar</button>
                                            </Col>
                                        </Row>


                                    </Form>
                                )}
                            </Formik>

                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Container>

    );

}

const mapStateToProps = (state) => {
    return {
        pos: state.pos,
        user: state.user
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        seleccionarMesaActionProps: (data) => { dispatch(seleccionarMesaAction(data)) },
        loadingActionProps: (data) => { dispatch(loadingAction(data)) }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(NuevoMeseroScreen);
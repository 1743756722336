import * as Yup from 'yup';

const productSchema = Yup.object().shape({
    nombre: Yup.string().required('Campo Obligatorio'),
    categoriaId: Yup.string().required("Campo Obligatorio"),
    unidadMedida: Yup.string().required("Campo Obligatorio"),
    precioVenta: Yup.string().required("Campo Obligatorio"),
    tipo: Yup.string().required("Campo Obligatorio"),
    estado: Yup.bool().required("Campo Obligatorio")
});

export default productSchema;
import React from "react";
import { connect } from 'react-redux';
import storeSVG from "../../../assets/img/store.svg";
import AbrirCajaComponent from "../../../components/caja";
import ModalComponent from "../../../components/modal";
import { abrirCajaAction, loadingAction } from "../../../redux/actions";
import { posService } from "../../../services";
function EstablecimientosScreen(props) {
  const { user, abrirCajaActionProps, loadingActionProps } = props;
  const [establecimientos, setEstablecimientos] = React.useState(null);
  const [isShowing, setIsShowing] = React.useState(false);
  const [dineroBase, setDineroBase] = React.useState(false);

  React.useEffect(() => {
    getEstablecimientos();
  }, []);

  const getEstablecimientos = async () => {
    loadingActionProps(true)
    try {
      const result = await posService.ObtenerEstablecimientos(user.data.TenantId);
      setEstablecimientos(result);
      loadingActionProps(false);
    } catch (error) {
      loadingActionProps(false);
    }

  };

  const handleAbrirCaja = (id) => {
    setIsShowing(true);
  }

  const abrirCaja = async () => {

    setIsShowing(false);
    const payload = {
      dineroBase: dineroBase,
      usuarioAbreId: user.data.UsuarioID,
    }
    const result = await posService.AbrirCaja(payload);
    abrirCajaActionProps({ cajaAbierta: true, cajaId: result.id });
  }

  const handleSeleccionarEstablecimiento = async (id) => {
    const result = await posService.SeleccionarEstablecimiento();
    abrirCajaActionProps({ cajaAbierta: true, cajaId: result.id });
  }

  return (
    <div className="container">
      <div className="row">
        {/* Abrir Caja Modal */}
        <ModalComponent isShowing={isShowing} setIsShowing={setIsShowing} title="Abrir Caja">
          <AbrirCajaComponent />
        </ModalComponent>

        <div className="col-lg-6 offset-lg-3 col-sm-12">
          <h3 className="text-center">Seleccionar Establecimiento</h3>
          {establecimientos &&
            <>
              <div className="card pointer" onClick={() => establecimientos.abierto ? handleSeleccionarEstablecimiento(establecimientos.id) : handleAbrirCaja(establecimientos.id)}>
                <div className="card-body">
                  <div className="card-title">
                    <div className="row d-flex align-items-center">
                      <div className="col-lg-2 col-sm-6">
                        <img src={storeSVG} style={{ width: "60px" }} />
                      </div>
                      <div className="col-lg-8 col-sm-6 d-flex align-items-center">
                        {establecimientos.nombre}
                        <br />
                        {establecimientos.alias}
                        <br />
                        {establecimientos.direccion}
                      </div>
                      <div className="col-lg-2 col-sm-12 d-flex align-items-center">
                        {establecimientos.abierto ? (
                          <div className="text-success">Abierto</div>
                        ) : (
                          <div className="text-danger">Cerrado</div>
                        )}
                        <br />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          }
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    pos: state.pos,
    user: state.user,
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    abrirCajaActionProps: (data) => { dispatch(abrirCajaAction(data)) },
    loadingActionProps: (data) => { dispatch(loadingAction(data)) }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(EstablecimientosScreen);

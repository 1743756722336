import  { fetch }  from '../../utils/fetch'

const VentasAnuales = async() => {
    
  try {
      const { data } = await fetch('dashboard', 'get')
      return data
    } catch (e) {
      return Promise.reject(e)
    }
}

export const dashboardService = {
    VentasAnuales
}
import { FieldArray, Form, Formik } from 'formik';
import React from 'react';
import {
    Card, Col, Container,
    Row
} from "react-bootstrap";
import AsyncSelect from 'react-select/async';
import Swal from 'sweetalert2';

import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { loadingAction } from '../../../redux/actions';
import { productosService } from '../../../services';

function NuevaCombinacion(props) {

    const { user, loadingActionProps, productoId } = props;
    // let initialValues = {
    //     combinaciones: [
    //         {
    //             ProductoBaseId: productoId,
    //             productoCombinadoId: '',
    //             Cantidad: ''
    //         }
    //     ]
    // }
    const [initialValues, setInitialValues] = React.useState({
        combinaciones: [
            {
                ProductoBaseId: productoId,
                productoCombinadoId: '',
                ProductoCombinado: "",
                Cantidad: ''
            }
        ]
    })
    const navigate = useNavigate();

    React.useEffect(() => {
        getProductosEstandar();
        getCombinaciones();
    }, [])

    const getProductosEstandar = async () => {
        try {
            const result = await productosService.ObtenerProductosByTipo()
            const values = result.map((data) => {
                return {
                    label: data.nombre + ' ' + '(' + data.unidadMedida + ')',
                    value: data.id
                }
            })
            return values;
        } catch (error) {

        }
    }

    const getCombinaciones = async () => {
        try {
            const result = await productosService.ObtenerCombinacion(productoId)
            let data = { combinaciones: [] };
            let values = result.map((data) => {
                return {
                    ProductoBaseId: productoId,
                    productoCombinadoId: data.productoCombinadoId,
                    ProductoCombinado: data.productoCombinado,
                    Cantidad: data.cantidad
                }
            })
            data.combinaciones = values;
            console.log("mostrando data", data)
            setInitialValues(data);
        } catch (error) {

        }
    }



    const submit = async (values) => {
        console.log("mostrando values", values);
        loadingActionProps(true);
        try {
            const result = await productosService.CrearCombinacion(values.combinaciones);
            loadingActionProps(false);
            Swal.fire({ icon: 'success', title: 'Éxito: ', text: 'Combinacion creada con éxito' })
            setTimeout(() => { navigate('/productos') }, 500)
        } catch (error) {
            Swal.fire({ icon: 'error', title: 'Ops...: ', text: 'Error al crear la combinación' })
            loadingActionProps(false);
        }

    }

    const handleInputChange = (e, name, name2, setFieldValue) => {
        console.log("mostrando new value", e);
        console.log("mostrando name", name);

        setFieldValue(name, e.value)
        setFieldValue(name2, e.label)
    };

    const loadOptions = (inputValue) => {
        return new Promise((resolve, reject) => {
            resolve(getProductosEstandar())
        })
    }

    return (
        <Container fluid>
            <Row>
                <Col lg={12}>
                    <Card>
                        <Card.Body>
                            <Formik
                                initialValues={initialValues}
                                enableReinitialize={true}
                                onSubmit={async (values, { resetForm }) => {
                                    submit(values, resetForm);

                                }}
                            >
                                {({ values, handleChange, handleBlur, setFieldValue }) => (
                                    <Form>
                                        <div className="mt-3 mb-3">
                                            <h3>Productos a combinar</h3>
                                        </div>

                                        <div className="row">
                                            <div className="col-5">
                                                <label>Producto</label>
                                            </div>
                                            <div className="col-5">
                                                <label>Cantidad</label>
                                            </div>
                                        </div>
                                        {initialValues.combinaciones && initialValues.combinaciones.length > 0 &&
                                            <FieldArray name="combinaciones">
                                                {({ insert, remove, push }) => (
                                                    <div>
                                                        {values.combinaciones.length > 0 && values.combinaciones.map((data, index) => {
                                                            console.log("mostrando dataaa", data)
                                                            return (
                                                                <div className="row mb-3" key={index}>
                                                                    <div className="col-5">
                                                                        <AsyncSelect
                                                                            cacheOptions
                                                                            loadOptions={loadOptions}
                                                                            defaultOptions
                                                                            value={{ label: data.ProductoCombinado, value: data.ProductoCombinado }}
                                                                            onChange={(e) => handleInputChange(e, `combinaciones.${index}.ProductoCombinadoId`, `combinaciones.${index}.ProductoCombinado`, setFieldValue)}
                                                                        />
                                                                    </div>
                                                                    <div className="col-5">
                                                                        <input type="number" name={`combinaciones.${index}.Cantidad`} value={values.combinaciones[index].Cantidad} onChange={handleChange} className="form-control" />
                                                                    </div>
                                                                    <div className="col-2 d-flex align-items-end">
                                                                        <button type="button" className="btn btn-danger" onClick={() => remove(index)}>x</button>
                                                                    </div>


                                                                </div>
                                                            )
                                                        })}
                                                        <button type="button" className="btn btn-secondary border-0 mt-3" onClick={() => push({ ProductoBaseId: productoId, ProductoCombinadoId: "", Cantidad: '', ProductoCombinado: "" })}>Agregar Producto</button>
                                                    </div>
                                                )}
                                            </FieldArray>
                                        }



                                        <Row className="text-right">
                                            <Col>
                                                <button type="submit" className="btn btn-primary border-0 mt-3">Guardar Combinacion</button>
                                            </Col>
                                        </Row>


                                    </Form>
                                )}
                            </Formik>

                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Container>
    )
}

const mapStateToProps = (state) => {
    return {
        pos: state.pos,
        user: state.user,
        mesaSeleccionada: state.pos.mesaSeleccionada
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        loadingActionProps: (data) => { dispatch(loadingAction(data)) }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(NuevaCombinacion);

import React from 'react';
import { useSelector } from 'react-redux';

const RenderComponentIfAllowed = ({children,allowedRoles}) => {
    const userData = useSelector(state => state.user.data)
    return (
        userData && allowedRoles.includes(userData.Role.toLowerCase()) ? children : null
    );
};

export default RenderComponentIfAllowed;
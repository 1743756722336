import React from "react";
import {
    Card, Col, Container,
    Row
} from "react-bootstrap";
import { Link } from 'react-router-dom';

import { connect } from 'react-redux';
import { loadingAction, seleccionarMesaAction } from "../../redux/actions";
import { productosService } from "../../services";


function ProductosScreen(props) {

    const [productos, setProductos] = React.useState(null);

    React.useEffect(() => {
        getProducts();
    }, []);

    const getProducts = async () => {
        const result = await productosService.ObtenerProductos();
        setProductos(result);
    }

    return (
        <Container fluid>
            <Row className="p-4">
                <Col lg={4}>
                    <h2>Listado de Productos</h2>
                </Col>
                <Col lg={8} className="text-end">
                    <Link className="btn btn-primary border-0 m-2" to={`/productos/nuevo`} >Nuevo Producto</Link>
                    <Link className="btn btn-primary border-0 m-2" to={`/productos/importar`} >Importar</Link>
                </Col>
            </Row>
            <Row>
                <Col lg={12}>
                    <Card>
                        <Card.Body>
                            <div className="table-responsive">
                                <table className="table table-striped table-hover">
                                    <thead>
                                        <tr>
                                            <th>Codigo</th>
                                            <th>Nombre</th>
                                            <th>Categoria</th>
                                            <th>Tipo</th>
                                            <th>Precio Venta</th>
                                            <th>Opciones</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {productos && productos.map((data, index) => {
                                            return (
                                                <tr key={index}>
                                                    <td>{data.codigoProducto}</td>
                                                    <td>{data.nombre}</td>
                                                    <td>{data.categoria?.nombre}</td>
                                                    <td>{data.tipo}</td>
                                                    <td>{data.precioVenta.toLocaleString('es')}</td>
                                                    <td>
                                                        <Link className="btn btn-secondary border-0 btn-xs" to={`/productos/ver/${data.id}`} ><i className="fa fa-pencil"></i></Link>
                                                    </td>
                                                </tr>
                                            )
                                        })}
                                    </tbody>
                                </table>

                            </div>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Container>

    );

}

const mapStateToProps = (state) => {
    return {
        pos: state.pos,
        user: state.user,
        mesaSeleccionada: state.pos.mesaSeleccionada
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        seleccionarMesaActionProps: (data) => { dispatch(seleccionarMesaAction(data)) },
        loadingActionProps: (data) => { dispatch(loadingAction(data)) }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProductosScreen);
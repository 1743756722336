import React from "react";
import {
    Card, Col, Container,
    Row
} from "react-bootstrap";
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { loadingAction, seleccionarMesaAction } from "../../redux/actions";
import { productosService } from "../../services";


function CategoriasScreen(props) {

    const [categorias, setCategorias] = React.useState(null);

    React.useEffect(() => {
        document.title = "Categorias - MiPosDigital"
        getData();
    }, []);

    const getData = async () => {
        const result = await productosService.ObtenerCategorias();
        console.log("mostrando result", result)
        setCategorias(result);
    }

    return (
        <Container fluid>
            <Row className="p-4">
                <Col lg={4}>
                    <h2>Listado de Categorias</h2>
                </Col>
                <Col lg={8} className="text-end">
                    <Link className="btn btn-primary border-0" to={`/categorias/nuevo`} >Nueva Categoría</Link>
                </Col>
            </Row>
            <Row>
                <Col lg={12}>
                    <Card>
                        <Card.Body>
                            <div className="table-responsive">
                                <table className="table table-striped table-hover">
                                    <thead>
                                        <tr>
                                            <th>Nombre</th>
                                            <th>Opciones</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {categorias && categorias.map((data, index) => {
                                            return (
                                                <tr key={index}>
                                                    <td>{data.nombre}</td>
                                                    <td></td>
                                                </tr>
                                            )
                                        })}
                                    </tbody>
                                </table>

                            </div>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Container>

    );

}

const mapStateToProps = (state) => {
    return {
        pos: state.pos,
        user: state.user,
        mesaSeleccionada: state.pos.mesaSeleccionada
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        seleccionarMesaActionProps: (data) => { dispatch(seleccionarMesaAction(data)) },
        loadingActionProps: (data) => { dispatch(loadingAction(data)) }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CategoriasScreen);
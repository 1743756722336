import React from 'react';

import { connect } from 'react-redux';
import EstablecimientosScreen from './establecimientos';
import MesasScreen from './mesas';
import PosScreen from './pos';

function VentasScreen(props) {
    const {pos} = props;
  return (
    <>
    {pos.cajaAbierta === false && 
    <EstablecimientosScreen/>
    }
    {pos.cajaAbierta === true && pos.mesaSeleccionada === null && 
    <MesasScreen/>
    }
    {pos.mesaSeleccionada != null && 
    <PosScreen/>
    }
    </>

  )
}

const mapStateToProps= (state) =>{
    return {
        pos: state.pos
    }
};
  
export default connect(mapStateToProps,null)(VentasScreen);
import React from 'react'
import { connect } from 'react-redux';
import { Link } from "react-router-dom";
import logoSmall from '../../assets/img/logo-small.png'
import { uiMenuCollapseAction } from '../../redux/actions';
import './header.scss'

function HeaderComponent(props) {

  const {uiState, collapseMenuActionProps} = props;

  const toggle = () => {
    collapseMenuActionProps();
    const body = document.querySelector('body');
    if(uiState.collapsed){
      body.classList.remove('sb-sidenav-toggled');
    }else{
      body.classList.add('sb-sidenav-toggled');
    }
    
    return true;
  }

  React.useEffect(()=>{
    toggle()
  },[])

  return (
    <>
      <div className="nav-header">
        <Link to="/dashboard" className="brand-logo">
          <img src={logoSmall} width={80} />
        </Link>
        <div className="nav-control">
          <button type="button" onClick={()=>toggle()} className="btn"><span className="fa fa-bars"></span></button>
        </div>
      </div>

    </>

  )
}

const mapStateToProps= (state) =>{
  return {
      uiState: state.ui
};
}

const mapDispatchToProps= (dispatch) =>{
  return {
    collapseMenuActionProps: () => {dispatch(uiMenuCollapseAction())}
}
}

export default connect(mapStateToProps,mapDispatchToProps)(HeaderComponent);


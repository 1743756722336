import { Form, Formik } from "formik";
import React from "react";
import {
    Card, Col, Container,
    Row
} from "react-bootstrap";
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import Swal from 'sweetalert2';
import { loadingAction, seleccionarMesaAction } from "../../../redux/actions";
import { productosService } from "../../../services";
import productSchema from "../validationSchema";

function NuevoProductoScreen(props) {

    const params = useParams();
    const { loadingActionProps } = props;
    const [categorias, setCategorias] = React.useState([]);
    const [dataValues, setDataValues] = React.useState({
        codigoProducto: '',
        nombre: '',
        categoriaId: '',
        precioCompra: 0,
        ivaCompra: 0,
        precioVenta: '',
        descripcion: "",
        foto: "",
        tipo: "",
        unidadMedida: "",
        estado: true,
    });

    React.useEffect(() => {
        getCategorias();
    }, []);

    React.useEffect(() => {
        if (params && params.id) {
            getProductById();
            getCategorias();
        }

    }, [params.id]);

    const getCategorias = async () => {
        loadingActionProps(true);
        const result = await productosService.ObtenerCategorias();
        console.log("mostrando result", result)
        loadingActionProps(false);
        setCategorias(result);
    }

    const getProductById = async () => {
        loadingActionProps(true);
        const result = await productosService.ObtenerProductosById(params.id);
        console.log("mostrando result", result)
        setDataValues(result)
        loadingActionProps(false);
        setCategorias(result);
    }

    const submit = async (params, resetForm) => {
        loadingActionProps(true);
        let isTrueSet = (params.estado === 'true');
        params.estado = isTrueSet;
        const payload = params
        try {
            const result = await productosService.CrearProducto(payload);
            console.log("mostrando result", result)
            loadingActionProps(false);
            Swal.fire({ icon: 'success', title: 'Éxito: ', text: 'Producto creado con éxito' })
            setTimeout(() => { resetForm(); }, 500)
        } catch (error) {
            Swal.fire({ icon: 'error', title: 'Ops...: ', text: 'Error al crear el producto' })
            loadingActionProps(false);
        }

    }

    const submitPut = async (params, resetForm) => {
        loadingActionProps(true);
        let isTrueSet = (params.estado === 'true');
        params.estado = isTrueSet;
        params.categoriaId = params.categoria.id
        delete params.categoria
        const payload = params
        try {
            const result = await productosService.ActualizarProducto(params.id, payload);
            console.log("mostrando result", result)
            loadingActionProps(false);
            Swal.fire({ icon: 'success', title: 'Éxito: ', text: 'Producto creado con éxito' })
            setTimeout(() => { resetForm(); }, 500)
        } catch (error) {
            Swal.fire({ icon: 'error', title: 'Ops...: ', text: 'Error al crear el producto' })
            loadingActionProps(false);
        }

    }

    return (
        <Container fluid>
            <Row className="p-4">
                <Col lg={12}>
                    {params.id ? <h2>Editar Producto</h2> : <h2>Nuevo Producto</h2>}

                </Col>
            </Row>
            <Row>
                <Col lg={12}>
                    <Card>
                        <Card.Body>
                            <Formik
                                initialValues={dataValues}
                                enableReinitialize={true}
                                validationSchema={productSchema}
                                onSubmit={async (values, { resetForm }) => {
                                    params.id ? submitPut(values, resetForm) : submit(values, resetForm);
                                }}>
                                {({ values, errors, touched, handleChange, handleBlur }) => (
                                    <Form>
                                        <Row>
                                            <Col>
                                                {JSON.stringify(errors)}
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <div className="mb-3">
                                                    <label>Codigo Producto</label>
                                                    <input type="text" className="form-control" name="codigoProducto" value={values.codigoProducto} onChange={handleChange} onBlur={handleBlur} />
                                                </div>
                                            </Col>
                                            <Col>
                                                <div className="mb-3">
                                                    <label>Nombre</label>
                                                    <input type="text" className="form-control" name="nombre" value={values.nombre} onChange={handleChange} onBlur={handleBlur} />
                                                    {errors.nombre || touched.nombre ? <div className="text-danger">{errors.nombre}</div> : null}
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <div className="mb-3">
                                                    <label>Categoria</label>
                                                    <select className="form-control" name="categoriaId" onChange={handleChange} value={values.categoriaId}>
                                                        <option value={""}>Seleccionar Categoria</option>
                                                        {categorias && categorias.length > 0 && categorias.map((data, index) => {
                                                            return (
                                                                <option key={index} value={data.id}>{data.nombre}</option>
                                                            )
                                                        })}
                                                    </select>
                                                    {errors.categoriaId || touched.categoriaId ? <div className="text-danger">{errors.categoriaId}</div> : null}
                                                </div>
                                            </Col>
                                            <Col>
                                                <div className="mb-3">
                                                    <label>Unidad medida</label>

                                                    <select className="form-control" name="unidadMedida" onChange={handleChange} value={values.unidadMedida}>
                                                        <option value={null}>Seleccionar</option>
                                                        <option value="Unidad">Unidad</option>
                                                        <option value="Libra">Libra</option>
                                                        <option value="Gramo">Gramo</option>
                                                    </select>
                                                    {errors.unidadMedida || touched.unidadMedida ? <div className="text-danger">{errors.unidadMedida}</div> : null}
                                                </div>
                                            </Col>


                                        </Row>
                                        <Row>
                                            <Col>
                                                <div className="mb-3">
                                                    <label>Iva Compra</label>
                                                    <input type="number" className="form-control" name="ivaCompra" value={values.ivaCompra} onChange={handleChange} onBlur={handleBlur} />
                                                </div>
                                            </Col>
                                            <Col>
                                                <div className="mb-3">
                                                    <label>Precio Compra</label>
                                                    <input type="number" className="form-control" name="precioCompra" value={values.precioCompra} onChange={handleChange} onBlur={handleBlur} />

                                                </div>
                                            </Col>
                                            <Col>
                                                <div className="mb-3">
                                                    <label>Precio Venta</label>
                                                    <input type="number" className="form-control" name="precioVenta" value={values.precioVenta} onChange={handleChange} onBlur={handleBlur} />
                                                    {errors.precioVenta || touched.precioVenta ? <div className="text-danger">{errors.precioVenta}</div> : null}
                                                </div>
                                            </Col>

                                        </Row>
                                        <Row>
                                            <Col>
                                                <div className="mb-3">
                                                    <label>Tipo de producto</label>

                                                    <select className="form-control" name="tipo" onChange={handleChange} value={values.tipo}>
                                                        <option value={null}>Seleccionar</option>
                                                        <option value="Estandar">Estándar (lleva inventario)</option>
                                                        <option value="Servicio">Servicio (no lleva inventario)</option>
                                                        <option value="Combinacion">Combinacion (combinacion de productos)</option>
                                                    </select>
                                                    {errors.tipo || touched.tipo ? <div className="text-danger">{errors.tipo}</div> : null}
                                                </div>
                                            </Col>
                                            <Col>
                                                <div className="mb-3">
                                                    <label>Estado del producto</label>
                                                    <select className="form-control" name="estado" onChange={handleChange} value={values.estado}>
                                                        <option value={null}>Seleccionar</option>
                                                        <option value={true} >Activo</option>
                                                        <option value={false}>Inactivo</option>
                                                    </select>
                                                    {errors.estado || touched.estado ? <div className="text-danger">{errors.estado}</div> : null}
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <div className="mb-3">
                                                    <label>Descripcion</label>
                                                    <textarea className="form-control" name="descripcion" onChange={handleChange} placeholder="Descripcion" value={values.descripcion}></textarea>
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <button type="submit" className="btn btn-primary border-0">Guardar Producto</button>
                                            </Col>
                                        </Row>
                                    </Form>
                                )}
                            </Formik>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Container>
    );
}

const mapStateToProps = (state) => {
    return {
        pos: state.pos,
        user: state.user,
        mesaSeleccionada: state.pos.mesaSeleccionada
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        seleccionarMesaActionProps: (data) => { dispatch(seleccionarMesaAction(data)) },
        loadingActionProps: (data) => { dispatch(loadingAction(data)) }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(NuevoProductoScreen);
import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { uiMenuCollapseAction, uiSelectMenuAction } from '../../redux/actions';
import './sidebar.scss';
import RenderComponentIfAllowed from '../renderComponent';

function Sidebar(props) {
    const { uiState, seleccionarMenuActionProps, collapseMenuActionProps } = props;

    const handleItemPos = () => {
        seleccionarMenuActionProps('pos');
        collapseMenuActionProps();
    }

    const logout = () => {
        localStorage.clear();
        window.location.reload()
    }
    return (
        <>
            <div className="sidebar">
                <div className="d-flex flex-column flex-shrink-0 sidebar-styles">
                    <ul className="nav nav-pills flex-column mb-auto">

                        <RenderComponentIfAllowed allowedRoles={["admin"]}>
                            <li className="nav-item pb-2">
                                <Link onClick={() => seleccionarMenuActionProps('dashboard')} className={`nav-link ${uiState.itemSeleccionado == 'dashboard' ? 'link-nav-active' : ''}`} to="/dashboard">
                                    <span className="fa fa-house"></span> <span className="mx-2">Dashboard</span>
                                </Link>
                            </li>
                        </RenderComponentIfAllowed>

                        <RenderComponentIfAllowed allowedRoles={["admin"]}>
                            <li className="nav-item pb-2">
                                <Link onClick={() => seleccionarMenuActionProps('clientes')} className={`nav-link ${uiState.itemSeleccionado == 'clientes' ? 'link-nav-active' : ''}`} to="/personas/clientes">
                                    <span className="fa fa-user-group"></span> <span className="mx-2">Clientes</span>
                                </Link>
                            </li>
                        </RenderComponentIfAllowed>

                        <RenderComponentIfAllowed allowedRoles={["admin"]}>
                        <li className="nav-item pb-2">
                            <Link onClick={() => seleccionarMenuActionProps('meseros')} className={`nav-link ${uiState.itemSeleccionado == 'meseros' ? 'link-nav-active' : ''}`} to="/personas/meseros">
                                <span className="fa fa-user-group"></span> <span className="mx-2">Meseros</span>
                            </Link>
                        </li>
                        <hr />
                        </RenderComponentIfAllowed>
                        
                        <RenderComponentIfAllowed allowedRoles={["admin","mesero"]}>
                        <li className="pb-2">
                            <Link onClick={() => handleItemPos('pos')} className={`nav-link ${uiState.itemSeleccionado == 'pos' ? 'link-nav-active' : ''}`} to="/pos">
                                <span className="fa fa-shop"></span> <span className="mx-2">Punto de Venta</span>
                            </Link>
                        </li>
                        <hr />
                        </RenderComponentIfAllowed>
                        
                        <RenderComponentIfAllowed allowedRoles={["admin"]}>
                        <li className="pb-2">
                            <Link onClick={() => seleccionarMenuActionProps('productos')} className={`nav-link ${uiState.itemSeleccionado == 'productos' ? 'link-nav-active' : ''}`} to="/productos">
                                <span className="fa fa-box"></span> <span className="mx-2">Productos</span>
                            </Link>
                        </li>
                        </RenderComponentIfAllowed>
                        <RenderComponentIfAllowed allowedRoles={["admin"]}>
                        <li className="pb-2">
                            <Link onClick={() => seleccionarMenuActionProps('categorias')} className={`nav-link ${uiState.itemSeleccionado == 'categorias' ? 'link-nav-active' : ''}`} to="/categorias">
                                <span className="fa fa-table-cells"></span> <span className="mx-2">Categorias</span>
                            </Link>
                        </li>
                        </RenderComponentIfAllowed>
                        <RenderComponentIfAllowed allowedRoles={["admin"]}>
                        <li className="pb-2">
                            <Link onClick={() => seleccionarMenuActionProps('movimientos')} className={`nav-link ${uiState.itemSeleccionado == 'movimientos' ? 'link-nav-active' : ''}`} to="/inventario/movimientos">
                                <span className="fa fa-boxes-stacked"></span> <span className="mx-2">Movimientos</span>
                            </Link>
                        </li>
                        <hr />
                        </RenderComponentIfAllowed>
                        
                        <RenderComponentIfAllowed allowedRoles={["admin"]}>
                        <li className="pb-2">
                            <Link onClick={() => seleccionarMenuActionProps('configuracion')} className={`nav-link ${uiState.itemSeleccionado == 'configuracion' ? 'link-nav-active' : ''}`} to="/configuracion">
                                <span className="fa fa-gear"></span> <span className="mx-2">Configuracion</span>
                            </Link>
                        </li>
                        </RenderComponentIfAllowed>
                        <RenderComponentIfAllowed allowedRoles={["admin"]}>
                        <li className="pb-2">
                            <Link onClick={() => seleccionarMenuActionProps('mesas')} className={`nav-link ${uiState.itemSeleccionado == 'mesas' ? 'link-nav-active' : ''}`} to="/mesas">
                                <span className="fa fa-gear"></span> <span className="mx-2">Mesas</span>
                            </Link>
                            <Link onClick={() => seleccionarMenuActionProps('zonas')} className={`nav-link ${uiState.itemSeleccionado == 'zonas' ? 'link-nav-active' : ''}`} to="/zonas">
                                <span className="fa fa-gear"></span> <span className="mx-2">Zonas</span>
                            </Link>
                        </li>
                        <hr />
                        </RenderComponentIfAllowed>
                        
                        <li className="pb-2">
                            <Link className="nav-link" onClick={() => logout()}>
                                <span className="fa fa-right-from-bracket"></span> <span className="mx-2">Salir</span>
                            </Link>
                        </li>
                    </ul>
                </div>
            </div>

        </>
    )
}

const mapStateToProps = (state) => {
    return {
        uiState: state.ui
    };
}

const mapDispatchToProps = (dispatch) => {
    return {
        seleccionarMenuActionProps: (data) => { dispatch(uiSelectMenuAction(data)) },
        collapseMenuActionProps: () => { dispatch(uiMenuCollapseAction()) }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Sidebar);
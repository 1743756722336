import React from 'react';
import {
    Card, Col, Container,
    Row
} from "react-bootstrap";
import { Link } from 'react-router-dom';
import { zonasService } from '../../services';
function ZonasScreen() {

    const [zonas, setZonas] = React.useState(null);

    React.useEffect(() => {
        document.title = "Lista de zonas"
        getZonas();
    }, []);

    const getZonas = async () => {
        const result = await zonasService.GetZonas();
        setZonas(result);
    }

    return (
        <Container fluid>
            <Row className="p-4">
                <Col lg={4}>
                    <h2>Listado de Zonas</h2>
                </Col>
                <Col lg={8} className="text-end">
                    <Link className="btn btn-primary border-0 m-2" to={`/zonas/nuevo`} >Nueva Zona</Link>
                </Col>
            </Row>
            <Row>
                <Col lg={12}>
                    <Card>
                        <Card.Body>
                            <div className="table-responsive">
                                <table className="table table-striped table-hover">
                                    <thead>
                                        <tr>
                                            <th>Nombre</th>
                                            <th>Opciones</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {zonas && zonas.map((data, index) => {
                                            return (
                                                <tr key={index}>
                                                    <td>{data.nombre}</td>
                                                    <td>
                                                        <Link className="btn btn-secondary border-0 btn-xs" to={`/zonas/ver/${data.id}`} ><i className="fa fa-pencil"></i></Link>
                                                    </td>
                                                </tr>
                                            )
                                        })}
                                    </tbody>
                                </table>

                            </div>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Container>

    )
}

export default ZonasScreen
import { fetch } from '../../utils/fetch'

const AbrirCaja = async (payload) => {

  try {
    const { data } = await fetch('caja/abrir', 'post', payload)
    return data
  } catch (e) {
    return Promise.reject(e)
  }
}

const SeleccionarEstablecimiento = async () => {

  try {
    const { data } = await fetch('caja/seleccionar', 'post')
    return data
  } catch (e) {
    return Promise.reject(e)
  }
}

const CerrarCaja = async (payload) => {

  try {
    const { data } = await fetch('caja/cerrar', 'post', payload)
    return data
  } catch (e) {
    return Promise.reject(e)
  }
}


const ObtenerCaja = async (id) => {

  try {
    const { data } = await fetch('caja/' + id, 'get')
    return data
  } catch (e) {
    return Promise.reject(e)
  }
}

const ObtenerCajas = async () => {

  try {
    const { data } = await fetch('caja/', 'get')
    return data
  } catch (e) {
    return Promise.reject(e)
  }
}

const ObtenerEstablecimientos = async (id) => {

  try {
    const { data } = await fetch('tenant/' + id, 'get')
    return data
  } catch (e) {
    return Promise.reject(e)
  }
}

const ActualizarEstablecimientos = async (id, payload) => {

  try {
    const { data } = await fetch('tenant/' + id, 'put', payload)
    return data
  } catch (e) {
    return Promise.reject(e)
  }
}

const ObtenerZonas = async (payload) => {

  try {
    const { data } = await fetch('zona', 'get')
    return data
  } catch (e) {
    return Promise.reject(e)
  }
}

const seleccionarMesa = async (payload) => {

  try {
    const { data } = await fetch('mesa-atencion', 'post', payload)
    return data
  } catch (e) {
    return Promise.reject(e)
  }
}

const ObtenerCategorias = async () => {

  try {
    const { data } = await fetch('categorias', 'get')
    return data
  } catch (e) {
    return Promise.reject(e)
  }
}

const ObtenerProductos = async () => {

  try {
    const { data } = await fetch('productos', 'get')
    return data
  } catch (e) {
    return Promise.reject(e)
  }
}




const AddPosSale = async (payload) => {

  try {
    const { data } = await fetch('MesaAtencionItems', 'post', payload)
    return data
  } catch (e) {
    return Promise.reject(e)
  }
}


const ObtenerMesaAtencion = async (id) => {

  try {
    const { data } = await fetch('mesa-atencion/' + id, 'get')
    return data
  } catch (e) {
    return Promise.reject(e)
  }
}

const ObtenerClientes = async () => {

  try {
    const { data } = await fetch('clientes/', 'get')
    return data
  } catch (e) {
    return Promise.reject(e)
  }
}

const ObtenerMeseros = async () => {

  try {
    const { data } = await fetch('auth/usuarios/role/Mesero', 'get')
    return data
  } catch (e) {
    return Promise.reject(e)
  }
}

const EliminarProductoMesaAtencion = async (id) => {

  try {
    const { data } = await fetch('MesaAtencionItems/' + id, 'delete')
    return data
  } catch (e) {
    return Promise.reject(e)
  }
}

const CerrarMesa = async (id) => {

  try {
    const { data } = await fetch('mesa-atencion/cerrar-mesa/' + id, 'delete')
    return data
  } catch (e) {
    return Promise.reject(e)
  }
}

const ActualizarMesaAtencion = async (id, payload) => {

  try {
    const { data } = await fetch('mesa-atencion/' + id, 'put', payload)
    return data
  } catch (e) {
    return Promise.reject(e)
  }
}

const ObtenerProductosByCategoria = async (id) => {

  try {
    const { data } = await fetch('productos/categoria/' + id, 'get')
    return data
  } catch (e) {
    return Promise.reject(e)
  }
}

const ProcesarVenta = async (payload) => {

  try {
    const { data } = await fetch('Facturas', 'post', payload)
    return data
  } catch (e) {
    return Promise.reject(e)
  }
}

const ObtenerFacturas = async (payload) => {

  try {
    const { data } = await fetch('Facturas', 'get')
    return data
  } catch (e) {
    return Promise.reject(e)
  }
}

const ObtenerArqueoCaja = async (id) => {

  try {
    const { data } = await fetch('caja/cerrar/' + id, 'post')
    return data
  } catch (e) {
    return Promise.reject(e)
  }
}

const CerrarCajaFinal = async (id, payload) => {

  try {
    const { data } = await fetch('caja/cerrar/step2/' + id, 'post', payload)
    return data
  } catch (e) {
    return Promise.reject(e)
  }
}

const EliminarFacturas = async (id) => {

  try {
    const { data } = await fetch('Facturas/' + id, 'delete')
    return data
  } catch (e) {
    return Promise.reject(e)
  }
}




export const posService = {
  AbrirCaja,
  ObtenerCaja,
  ObtenerCajas,
  SeleccionarEstablecimiento,
  CerrarCaja,
  ObtenerEstablecimientos,
  ObtenerZonas,
  ObtenerCategorias,
  ObtenerProductos,
  ObtenerClientes,
  ObtenerMeseros,
  seleccionarMesa,
  AddPosSale,
  ObtenerMesaAtencion,
  EliminarProductoMesaAtencion,
  CerrarMesa,
  ActualizarMesaAtencion,
  ObtenerProductosByCategoria,
  ProcesarVenta,
  ObtenerFacturas,
  EliminarFacturas,
  ObtenerArqueoCaja,
  CerrarCajaFinal,
  ActualizarEstablecimientos
}
import React from "react";
import {
  Card, Col, Container,
  Row
} from "react-bootstrap";
import { connect } from 'react-redux';
import { toast, ToastContainer } from 'react-toastify';
import { AlertConfirm } from "../../../components/alert";
import ClientesComponent from "../../../components/clientes";
import ComandaComponent from "../../../components/comanda";
import FacturaComponent from '../../../components/factura';
import ModalComponent from "../../../components/modal";
import PreFacturaComponent from "../../../components/pre-factura";
import ProcesarVentaComponent from "../../../components/procesar-venta";
import { loadingAction, seleccionarMesaAction } from "../../../redux/actions";
import { posService } from "../../../services";
import "./pos.scss";
import RenderComponentIfAllowed from "../../../components/renderComponent";
import { Formik } from 'formik';
function PosScreen(props) {
  const { seleccionarMesaActionProps, pos, loadingActionProps, user } = props;
  const [categorias, setCategorias] = React.useState(null);
  const [categoriaSeleccionada, setCategoriaSeleccionada] = React.useState(null);
  const [isShowing, setIsShowing] = React.useState(false);
  const [showModalProcesarVenta, setShowModalProcesarVenta] = React.useState(false);
  const [showModalComanda, setShowModalComanda] = React.useState(false);
  const [showModalPreFactura, setShowModalPreFactura] = React.useState(false);
  const [showModalFactura, setShowModalFactura] = React.useState(false);
  const [productos, setProductos] = React.useState(null);
  const [clientes, setClientes] = React.useState(null);
  const [meseros, setMeseros] = React.useState(null);
  const [subTotal, setSubTotal] = React.useState(null);
  const [total, setTotal] = React.useState(null);
  const [grandTotal, setGrandTotal] = React.useState(null);
  const [mesaAtencion, setMesaAtencion] = React.useState(null);
  const [propina, setPropina] = React.useState(null);
  const [tipoPropina, setTipoPropina] = React.useState("porcentaje");
  const [valorPropina, setValorPropina] = React.useState(10);
  const [facturaResponse, setFacturaResponse] = React.useState(null);
  

  const payloadToProcessSale = {
    tenantId: user.data.TenantId,
    clienteId: mesaAtencion?.cliente?.id,
    usuarioMeseroId: mesaAtencion?.mesero?.id,
    usuarioCreaId: user.data.UsuarioID,
    totalItems: mesaAtencion?.mesaAtencionItems?.length,
    subtotal: subTotal,
    descuento: 0,
    iva: 0,
    total: total,
    grandTotal: grandTotal,
    tipoPropina: tipoPropina,
    valorPropina: valorPropina,
    cajaId: pos.cajaId,
    facturaItems: mesaAtencion && mesaAtencion.mesaAtencionItems.length > 0 && mesaAtencion.mesaAtencionItems.map((data, index) => {
      return {
        tenantId: user.data.TenantId,
        productoId: data.producto.id,
        cantidad: data.cantidad,
      }
    })
  } 


  React.useEffect(() => {
    getData();
  }, []);

  React.useEffect(() => {
    if (mesaAtencion) {
      calcularSubtotal();
    }
  }, [mesaAtencion])

  const getData = async () => {
    loadingActionProps(true);

    const resultCategorias = await posService.ObtenerCategorias();
    const resultProdutos = await posService.ObtenerProductos();
    const resultMesaAtencion = await posService.ObtenerMesaAtencion(pos.mesaAtencionId);
    const resultClientes = await posService.ObtenerClientes();
    const resultMeseros = await posService.ObtenerMeseros();
    Promise.all([resultCategorias, resultProdutos, resultMesaAtencion, resultClientes, resultMeseros]).then(response => {
      setCategorias(response[0]);
      setProductos(response[1]);
      setMesaAtencion(response[2]);
      setClientes(response[3]);
      setMeseros(response[4]);
      setPropina(response[2].propina)
      setTipoPropina(response[2].tipoPropina)
      setValorPropina(response[2].valorPropina)
      loadingActionProps(false);
      calcularSubtotal();


    })

  };

  const getProductos = async () => {
    const result = await posService.ObtenerProductos();
    setProductos(result);
  }
  const getClientes = async () => {
    const data = await posService.ObtenerClientes();
    console.log(data);
    setClientes(data);
  }

  const calcularSubtotal = () => {
    const array = mesaAtencion.mesaAtencionItems;
    let subtotal = 0;
    let total = 0;
    let grandtotal = 0;

    //Calcular subtotal
    for (let index = 0; index < array.length; index++) {
      const element = array[index];
      const cantidad = element.cantidad
      const precioVenta = element.producto.precioVenta;
      subtotal += cantidad * precioVenta;
      total += cantidad * precioVenta;
      grandtotal += cantidad * precioVenta;
    }
    setSubTotal(subtotal);
    setTotal(total);
    setGrandTotal(grandtotal);

    //calcular valores de la propina
    let propina = mesaAtencion.propina
    //validar si tiene propina
    if (propina) {
      console.log("tiene propina");
      let tipoPropina = mesaAtencion.tipoPropina;
      let valorPropina = mesaAtencion.valorPropina;
      console.log("tipoPropina", tipoPropina);
      //console.log("valorPropina", valorPropina);
      //propina en cantidad fija
      if (tipoPropina == 'cantidadfija') {
        console.log("cantidadfija valorPropina", valorPropina);
        setGrandTotal(total + valorPropina);
      }
      //propina en porcentaje
      if (tipoPropina == 'porcentaje') {
        console.log("porcentaje valorPropina", valorPropina);
        setGrandTotal(grandtotal + (total * (valorPropina / 100)));
      }
    }

    console.log('[Mostrando subtotal]', subtotal);
    console.log('[Mostrando total]', total);
    console.log('[Mostrando grandTotal]', grandtotal);
  }

  const getDataMesaAtencion = async () => {
    const resultMesaAtencion = await posService.ObtenerMesaAtencion(pos.mesaAtencionId);
    setMesaAtencion(resultMesaAtencion);
  };

  const handleSearchByCategory = async (id) => {
    if (id == null) {
      getProductos();
      setCategoriaSeleccionada(null)

    } else {
      setCategoriaSeleccionada(id)
      const result = await posService.ObtenerProductosByCategoria(id);
      setProductos(result);
    }

  };

  const handleVolverAtras = () => {
    seleccionarMesaActionProps({ mesaSeleccionada: null, mesaAtencionId: null })
  }

  const handleAddPosSale = async (productoId) => {
    loadingActionProps(true)
    const payload = {
      estado: true,
      productoId: productoId,
      cantidad: 1,
      numero: 1,
      mesaAtencionId: pos.mesaAtencionId,
      cajaId: pos.cajaId,
      mesaId: pos.mesaSeleccionada,
    }
    const result = await posService.AddPosSale(payload);
    console.log('mostrando result', result);
    loadingActionProps(false)
    getDataMesaAtencion();

  }

  const handleDeleteProduct = async (id) => {
    loadingActionProps(true)
    await posService.EliminarProductoMesaAtencion(id)
    loadingActionProps(false)
    getDataMesaAtencion();
  }

  const handleCloseTable = async () => {
    AlertConfirm('Está a punto de cerrar la mesa, esto va a eliminar todos los productos asociados a esta mesa', 'question', async (response) => {
      console.log(response)
      if (response.isConfirmed) {
        loadingActionProps(true)
        await posService.CerrarMesa(pos.mesaAtencionId)
        loadingActionProps(false)
        seleccionarMesaActionProps({ mesaSeleccionada: null, mesaAtencionId: null })
      }
    })
  }

  const handleCloseTableAfterProcessBill = async () => {
    loadingActionProps(true)
    await posService.CerrarMesa(pos.mesaAtencionId)
    loadingActionProps(false)
    seleccionarMesaActionProps({ mesaSeleccionada: null, mesaAtencionId: null })
  }

  const handleChangeCliente = async (id) => {
    if (id == "00000000-0000-0000-0000-000000000000") {
      return toast.error("No puede asignar un cliente vacio");
    }
    const payload = {
      clienteId: id
    }
    loadingActionProps(true)
    await posService.ActualizarMesaAtencion(pos.mesaAtencionId, payload)
    loadingActionProps(false)
    getDataMesaAtencion();
  }

  const handleChangeMesero = async (id) => {
    const payload = {
      meseroId: id
    }
    loadingActionProps(true)
    await posService.ActualizarMesaAtencion(pos.mesaAtencionId, payload)
    loadingActionProps(false)
    getDataMesaAtencion();
  }

  const handleEnablePropina = async (data) => {
    let payload;
    if (data == "true") {
      setPropina(true);
      payload = {
        tipoPropina: 'porcentaje',
        valorPropina: 10,
        propina: true
      }
    } else {
      setPropina(false);
      payload = {
        tipoPropina: null,
        valorPropina: 0,
        propina: false
      }      
    }
    //Por defecto porcentaje
    setTipoPropina("porcentaje");
    setValorPropina(10);
    loadingActionProps(true)
    await posService.ActualizarMesaAtencion(pos.mesaAtencionId, payload)
    loadingActionProps(false)
    getDataMesaAtencion();
  }

  const handleChangeTipoPropina = async (data) => {
    setTipoPropina(data);

    const payload = {
      tipoPropina: data,
      valorPropina: 0
    }

    if(data == 'porcentaje'){
      setValorPropina(10);
      payload.valorPropina = 10;   
    }else{
      setValorPropina(0);
      payload.valorPropina = 0;   
    }
    
    loadingActionProps(true)
    await posService.ActualizarMesaAtencion(pos.mesaAtencionId, payload)
    loadingActionProps(false)
    getDataMesaAtencion();
  }

  const handleChangeValorPropina = async (data) => {
    setValorPropina(data);
  }

  const handleChangeValorPropinaUpdate = async (data) => {
    const payload = {
      valorPropina: data >0 ? data : valorPropina
    }
    loadingActionProps(true)
    await posService.ActualizarMesaAtencion(pos.mesaAtencionId, payload)
    loadingActionProps(false)
    getDataMesaAtencion();
  }

  const handleProcesarVenta = (values) => {
    if (!mesaAtencion.cliente) {
      toast.error("Debe seleccionar un cliente primero");
      return;
    }
    if (mesaAtencion.mesaAtencionItems.length <= 0) {
      toast.error("Debe tener al menos un producto en la lista");
      return;
    }
    setShowModalProcesarVenta(true);



    //if(mesaAtencion && mesaAtencion.cliente?.id)
    //
  }

  const handleSearch = (e) => {
    const value = e.target.value;
    let search = new RegExp(value, 'i');
    let tmpProductos = productos.filter(data => search.test(data.nombre));
    console.log("mostrando resultados", tmpProductos)
    if (value == '') {
      getProductos();
    }
    setProductos(tmpProductos);
  }


  return (
    <>
      <ToastContainer />
      {/* Crear Cliente Modal */}
      <ModalComponent isShowing={isShowing} setIsShowing={setIsShowing} title="Crear Cliente">
        <ClientesComponent getClientes={() => getClientes()} setIsShowing={setIsShowing} />
      </ModalComponent>

      {/* Procesar Venta Modal */}
      <ModalComponent isShowing={showModalProcesarVenta} setIsShowing={setShowModalProcesarVenta} title="Procesar Venta">
        <ProcesarVentaComponent  setShowModalFactura={setShowModalFactura} setFacturaResponse={setFacturaResponse} setIsShowing={setShowModalProcesarVenta} payloadToProcessSale={payloadToProcessSale} mesaAtencion={mesaAtencion} />
      </ModalComponent>

      {/* Factura Modal */}
      <ModalComponent isShowing={showModalFactura} setIsShowing={handleCloseTableAfterProcessBill} title="Factura">
        <FacturaComponent mesaAtencion={mesaAtencion} facturaResponse={facturaResponse} subTotal={subTotal} total={total} valorPropina={valorPropina} tipoPropina={tipoPropina} />
      </ModalComponent>

      {/* Comanda Modal */}
      <ModalComponent isShowing={showModalComanda} setIsShowing={setShowModalComanda} title="Comanda">
        <ComandaComponent mesaAtencion={mesaAtencion} />
      </ModalComponent>

      {/* Prefactura Modal */}
      <ModalComponent isShowing={showModalPreFactura} setIsShowing={setShowModalPreFactura} title="Pre-Factura">
        <PreFacturaComponent mesaAtencion={mesaAtencion} subTotal={subTotal} total={total} grandTotal={grandTotal} valorPropina={valorPropina} tipoPropina={tipoPropina} />
      </ModalComponent>



      <Container fluid>
        <Row>
          <Col className="text-center">
            <h2>Mesa - {mesaAtencion && mesaAtencion.mesa.nombre}</h2>
          </Col>
        </Row>
        <Row>
          {/* Seccion de ventas */}
          <Col lg={5} className="p-1 colum-cart">
            <Card className="h-100">
              <Card.Body>
                <Container>
                  <Row>

                  </Row>
                  <Row>
                    <Col>
                      <button type="button" id="elegirComanda" className="btn btn-secondary border-0 m-1" onClick={() => setShowModalComanda(true)}>Comanda</button>
                      
                      <RenderComponentIfAllowed allowedRoles={["admin"]}>
                        <button type="button" id="elegirPreFactura" className="btn btn-secondary border-0 m-1" onClick={() => setShowModalPreFactura(true)}>Pre-Factura</button>
                      </RenderComponentIfAllowed>
                      
                      <button type="button" id="elegirNuevoCliente" className="btn btn-secondary border-0 m-1" onClick={() => setIsShowing(true)}>Nuevo Cliente</button>
                      
                    </Col>

                  </Row>
                  <Row>
                    <Col lg={6} className="mt-3">

                      <div className="form-group mb-2">
                        <h3>Cliente</h3>
                        <select className="form-control" id="elegirCliente" value={mesaAtencion?.cliente?.id} onChange={(e) => { handleChangeCliente(e.target.value) }}>
                          <option value="00000000-0000-0000-0000-000000000000">Elegir</option>
                          {clientes && clientes.map((data, index) => {
                            return (
                              <option value={data.id} key={index}>{data.nombre} - {data.telefono}</option>
                            )
                          })}
                        </select>
                      </div>

                    </Col>
                    <Col lg={6} className="mt-3">


                      <div className="form-group mb-2">
                        <h3>Mesero/Camarero</h3>
                        <select className="form-control" id="elegirMesero" value={mesaAtencion?.mesero?.id} onChange={(e) => { handleChangeMesero(e.target.value) }}>
                          <option>Elegir</option>
                          {meseros && meseros.map((data, index) => {
                            return (
                              <option value={data.id} key={index}>{data.nombreCompleto}</option>
                            )
                          })}
                        </select>
                      </div>
                    </Col>
                  </Row>
                  <hr />

                  <hr />
                  <Row className="mb-2">
                    <div className="cart-list" id="listaProductosAgregados">
                      {mesaAtencion &&
                        mesaAtencion.mesaAtencionItems.map((data, index) => {
                          return (
                            <div className="card mb-2">
                              <div className="card-body">
                                <div className="row d-flex align-items-center">
                                <div className="col-10">
                                    <div className="card-title">
                                    {data.cantidad} x <b>{data.producto.nombre}</b>
                                    </div>
                                    <span className="text-muted">
                                    {data.producto.precioVenta.toLocaleString('es-Es')}
                                    </span>
                                  </div>
                                  
                                  <RenderComponentIfAllowed allowedRoles={["admin"]}>
                                    <div className="col-2">
                                        <button type="button" className="btn btn-secondary border-0 btn-xs" onClick={() => handleDeleteProduct(data.id)}><i className="fa fa-trash"></i></button>
                                      </div>
                                  </RenderComponentIfAllowed>
                                  
                                </div>
                              </div>
                            </div>

                          );
                        })}

                    </div>

                  </Row>
                  <Row>
                    <Col>
                      <div className="table-responsive col-sm-12 totalTab">
                        <table className="table">
                          <tbody>
                            <tr id="tourSubtotal">
                              <td className="active" width="40%">SubTotal</td>
                              <td className="whiteBg d-flex" style={{ 'justifyContent': 'space-between' }} width="100%">
                                <div className="">
                                  <span id="Subtot">{subTotal && subTotal}</span> COP
                                </div>
                                <div className="">
                                  <span className="float-right"><b id="ItemsNum"><span>2</span> items</b></span>
                                </div>


                              </td>
                            </tr>
                            <RenderComponentIfAllowed allowedRoles={["admin"]}>
                              <tr id="tourIva">
                                <td className="active" >Impuesto IVA</td>
                                <td className="whiteBg"><input type="text" className="form-control" placeholder="N/A" maxLength={8} readOnly />
                                  <span className="float-right"><b id="taxValue">COP</b></span>
                                </td>
                              </tr>
                            </RenderComponentIfAllowed>
                            
                            {/* <tr>
                              <td className="active">Descuento</td>
                              <td className="whiteBg">
                                <input type="text"  className="form-control" placeholder="N/A" maxLength={8} />
                                <span className="float-right"><b id="RemiseValue">COP</b></span>
                              </td>
                            </tr> */}
                            <tr id="tourTotal">
                              <td className="active" >Total</td>
                              <td className="whiteBg light-blue text-bold">
                                <span id="Total">{total && total}</span> COP
                              </td>
                            </tr>
                            <tr id="tourGTotal">
                              <td className="active" >Grand Total</td>
                              <td className="whiteBg light-blue text-bold">
                                <span id="Total">{grandTotal && grandTotal}</span> COP
                              </td>
                            </tr>
                            <RenderComponentIfAllowed allowedRoles={["admin"]}>
                            <tr id="tourPropina">
                              <td><label>Incluir Propina ?</label><span id="enable_propina" /><span id="enable_pcte_propina" /></td>
                              <td>
                                <select value={propina} onChange={(e) => handleEnablePropina(e.target.value)} className="form-control">
                                  <option value="">Seleccionar Opcion</option>
                                  <option value={true}>Si</option>
                                  <option value={false}>No</option>
                                </select>
                              </td>
                            </tr>
                            </RenderComponentIfAllowed>
                            
                            {propina == true &&
                              <>
                                <tr>
                                  <td><label>Tipo de propina</label><span id="enable_propina" /><span id="enable_pcte_propina" /></td>
                                  <td>
                                    <select value={tipoPropina} onChange={(e) => handleChangeTipoPropina(e.target.value)} className="form-control">
                                      <option value>Seleccionar</option>
                                      <option value="cantidadfija">Cantidad Fija Ej: 1000</option>
                                      <option value="porcentaje">Porcentaje Ej: 10%</option>
                                    </select>
                                  </td>
                                </tr>
                                {tipoPropina == 'porcentaje' &&
                                  <tr>
                                    <td><b>% de Propina</b></td>
                                    <td>
                                      <input type="text" value={valorPropina} onChange={(e) => handleChangeValorPropina(e.target.value)} onBlur={(e) => handleChangeValorPropinaUpdate()} className="form-control" />
                                    </td>
                                  </tr>
                                }
                                {tipoPropina == 'cantidadfija' &&
                                  <tr>
                                    <td><b>Valor de Propina</b></td>
                                    <td>
                                      <input type="number" value={valorPropina} onChange={(e) => handleChangeValorPropina(e.target.value)} onBlur={(e) => handleChangeValorPropinaUpdate()} className="form-control" />
                                    </td>
                                  </tr>
                                }
                              </>
                            }
                          </tbody></table>
                      </div>

                    </Col>
                  </Row>

                  <Row className="g-0">
                    <RenderComponentIfAllowed allowedRoles={["admin"]}> 
                    <Col className="mx-1">
                      <button type="button" id="tourCerrarMesa" className="btn btn-labeled btn-secondary w-100 border-0 rounded-0" onClick={() => handleCloseTable()}>
                        <span className="btn-label"><i className="fa fa-close"></i></span>
                        <span className="btn-text">CERRAR MESA</span>
                      </button>
                      </Col>
                    </RenderComponentIfAllowed>
                    
                    
                    
                    <Col className="mx-1">
                      <button type="button" id="tourVolver" className="btn btn-labeled btn-secondary w-100 border-0 rounded-0" onClick={() => handleVolverAtras()}>
                        <span className="btn-label"><i className="fa fa-arrow-left"></i></span>
                        <span className="btn-text">VOLVER</span>
                      </button>
                    </Col>

                    <RenderComponentIfAllowed allowedRoles={["admin"]}>
                    <Col className="mx-1">
                      <button type="button" id="tourProcesar" className="btn btn-labeled btn-secondary btn-block w-100 border-0 rounded-0" onClick={() => handleProcesarVenta()}>
                        <span className="btn-label"><i className="fa fa-check"></i></span>
                        <span className="btn-text">PROCESAR</span>
                      </button>
                    </Col>
                    </RenderComponentIfAllowed>
                  </Row>
                </Container>
              </Card.Body>
            </Card>
          </Col>
          {/* Seccion de productos */}
          <Col lg={7} className="p-1 colum-products">
            <Card>
              <Card.Body>
                <Container>
                  <Row className="row-horizon">
                    <Col className="p-0">
                      {/* Categorias */}
                      <span onClick={() => handleSearchByCategory(null)} className={`categories ${categoriaSeleccionada == null ? 'active' : ''}`}>Todas</span>
                      {categorias &&
                        categorias.map((data, index) => {
                          return (
                            <span id="tourCategorias" key={index} onClick={() => handleSearchByCategory(data.id)} className={`categories ${categoriaSeleccionada == data.id ? 'active' : ''}`}>
                              {data.nombre}
                            </span>
                          );
                        })}
                    </Col>
                  </Row>
                  {/* Buscador */}
                  <Row>
                    <div id="tourBuscador" class="input-group mb-3">
                      <button type="button" className="btn btn-dark btn-xs">Buscar</button>
                      <input type="text" className="form-control" onBlur={(e) => handleSearch(e)} placeholder="Buscar" />
                    </div>
                  </Row>
                  <Row id="tourProductos">
                    {/* Productos */}
                    {productos &&
                      productos.map((data, index) => {
                        return (
                          <Col key={index} className="mt-3">
                            <Card className="pointer card-products" onClick={() => handleAddPosSale(data.id)}>
                              <Card.Img variant="top" src={data.foto} />
                              <Card.Body>
                                <Card.Title className="">{data.precioVenta.toLocaleString('es-Es')}</Card.Title>
                                <Card.Text className="card-products-text">{data.nombre}</Card.Text>
                              </Card.Body>
                            </Card>
                          </Col>
                        );
                      })}
                  </Row>
                </Container>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    pos: state.pos,
    user: state.user,
    mesaSeleccionada: state.pos.mesaSeleccionada
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    seleccionarMesaActionProps: (data) => { dispatch(seleccionarMesaAction(data)) },
    loadingActionProps: (data) => { dispatch(loadingAction(data)) }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(PosScreen);
import { useFormik } from "formik";
import React from "react";
import {
    Card, Col, Container, Form, Row, Tab,
    Tabs
} from "react-bootstrap";
import { connect } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import Swal from 'sweetalert2';
import { loadingAction, seleccionarMesaAction } from "../../../redux/actions";
import { productosService } from "../../../services";
import NuevaCombinacion from "../combinacion";
import InventarioProducto from "../inventario";
import productSchema from "../validationSchema";

function VerProductoScreen(props) {
    const navigate = useNavigate();
    const params = useParams();
    const { loadingActionProps } = props;
    const [categorias, setCategorias] = React.useState([]);

    React.useEffect(() => {
        getCategorias();
    }, []);

    React.useEffect(() => {
        if (params && params.id) {
            getProductById();
        }

    }, [params.id]);

    const formik = useFormik({
        initialValues: {
            codigoProducto: '',
            nombre: '',
            categoriaId: '',
            precioCompra: 0,
            ivaCompra: 0,
            precioVenta: '',
            descripcion: "",
            foto: "",
            tipo: "",
            unidadMedida: "",
            estado: true,
        },
        validationSchema: productSchema,
        onSubmit: (values) => {
            submitPut(values);
        }
    })

    const getCategorias = async () => {
        loadingActionProps(true);
        const result = await productosService.ObtenerCategorias();
        console.log("mostrando categorias", result)
        loadingActionProps(false);
        setCategorias(result);
    }

    const getProductById = async () => {
        loadingActionProps(true);
        try {
            const result = await productosService.ObtenerProductosById(params.id);
            delete result.categoria;
            console.log("mostrando result", result)
            formik.setValues(result);
            loadingActionProps(false);
        } catch (error) {
            console.log("se presentó un error", error);
        }

    }

    const submitPut = async (params) => {
        loadingActionProps(true);
        let isTrueSet = (params.estado === 'true');
        params.estado = isTrueSet;
        delete params.categoria
        const payload = params
        try {
            const result = await productosService.ActualizarProducto(params.id, payload);
            loadingActionProps(false);
            Swal.fire({ icon: 'success', title: 'Éxito: ', text: 'Producto actualizado con éxito' })
            setTimeout(() => { navigate('/productos') }, 500)
        } catch (error) {
            Swal.fire({ icon: 'error', title: 'Ops...: ', text: 'Error al editar el producto' })
            loadingActionProps(false);
        }

    }

    return (
        <Container fluid>
            <Row className="p-4">
                <Col lg={12}>
                    <h2>Editar Producto</h2>
                </Col>
            </Row>
            <Row>
                <Col lg={12}>
                    <Card>
                        <Card.Body>
                            <Tabs defaultActiveKey="detalles" id="uncontrolled-tab-example" className="mb-3">
                                <Tab eventKey="detalles" title="Detalles">
                                    <Form onSubmit={formik.handleSubmit}>
                                        <Row>
                                            <Col>
                                                <div className="mb-3">
                                                    <label>Codigo Producto</label>
                                                    <input type="text" className="form-control" name="codigoProducto" defaultValue={formik.values.codigoProducto} onChange={formik.handleChange} onBlur={formik.handleBlur} />
                                                </div>
                                            </Col>
                                            <Col>
                                                <div className="mb-3">
                                                    <label>Nombre</label>
                                                    <input type="text" className="form-control" name="nombre" defaultValue={formik.values.nombre} onChange={formik.handleChange} onBlur={formik.handleBlur} />
                                                    {formik.errors.nombre || formik.touched.nombre ? <div className="text-danger">{formik.errors.nombre}</div> : null}
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <div className="mb-3">
                                                    <label>Categoria</label>
                                                    <select className="form-control" name="categoriaId" onChange={formik.handleChange} value={formik.values.categoriaId}>
                                                        {categorias && categorias.length > 0 && categorias.map((data, index) => {
                                                            return (
                                                                <option key={index} value={data.id}>{data.nombre}</option>
                                                            )
                                                        })}

                                                    </select>
                                                    {formik.errors.categoriaId || formik.touched.categoriaId ? <div className="text-danger">{formik.errors.categoriaId}</div> : null}
                                                </div>
                                            </Col>
                                            <Col>
                                                <div className="mb-3">
                                                    <label>Unidad medida</label>

                                                    <select className="form-control" name="unidadMedida" onChange={formik.handleChange} value={formik.values.unidadMedida}>
                                                        {formik.values?.unidadMedida == '' && <option>Seleccionar</option>}
                                                        <option value="Unidad">Unidad</option>
                                                        <option value="Libra">Libra</option>
                                                        <option value="Gramo">Gramo</option>
                                                    </select>
                                                    {formik.errors.unidadMedida || formik.touched.unidadMedida ? <div className="text-danger">{formik.errors.unidadMedida}</div> : null}
                                                </div>
                                            </Col>


                                        </Row>
                                        <Row>
                                            <Col>
                                                <div className="mb-3">
                                                    <label>Iva Compra</label>
                                                    <input type="number" className="form-control" name="ivaCompra" defaultValue={formik.values.ivaCompra} onChange={formik.handleChange} onBlur={formik.handleBlur} />
                                                </div>
                                            </Col>
                                            <Col>
                                                <div className="mb-3">
                                                    <label>Precio Compra</label>
                                                    <input type="number" className="form-control" name="precioCompra" defaultValue={formik.values.precioCompra} onChange={formik.handleChange} onBlur={formik.handleBlur} />
                                                </div>
                                            </Col>
                                            <Col>
                                                <div className="mb-3">
                                                    <label>Precio Venta</label>
                                                    <input type="number" className="form-control" name="precioVenta" defaultValue={formik.values.precioVenta} onChange={formik.handleChange} onBlur={formik.handleBlur} />
                                                    {formik.errors.precioVenta || formik.touched.precioVenta ? <div className="text-danger">{formik.errors.precioVenta}</div> : null}
                                                </div>
                                            </Col>

                                        </Row>
                                        <Row>
                                            <Col>
                                                <div className="mb-3">
                                                    <label>Tipo de producto</label>

                                                    <select className="form-control" name="tipo" onChange={formik.handleChange} value={formik.values.tipo}>
                                                        {formik.values?.tipo == '' && <option>Seleccionar</option>}
                                                        <option value="Estandar">Estándar (lleva inventario)</option>
                                                        <option value="Servicio">Servicio (no lleva inventario)</option>
                                                        <option value="Combinacion">Combinacion (combinacion de productos)</option>
                                                    </select>
                                                    {formik.errors.tipo || formik.touched.tipo ? <div className="text-danger">{formik.errors.tipo}</div> : null}
                                                </div>
                                            </Col>
                                            <Col>
                                                <div className="mb-3">
                                                    <label>Estado del producto</label>
                                                    <select className="form-control" name="estado" onChange={formik.handleChange} value={formik.values.estado}>
                                                        {formik.values?.estado == '' && <option>Seleccionar</option>}
                                                        <option value={true} >Activo</option>
                                                        <option value={false}>Inactivo</option>
                                                    </select>
                                                    {formik.errors.estado || formik.touched.estado ? <div className="text-danger">{formik.errors.estado}</div> : null}
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <div className="mb-3">
                                                    <label>Descripcion</label>
                                                    <textarea className="form-control" name="descripcion" onChange={formik.handleChange} placeholder="Descripcion" defaultValue={formik.values.descripcion}></textarea>
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <button type="submit" className="btn btn-primary">Guardar Producto</button>
                                            </Col>
                                        </Row>


                                    </Form>
                                </Tab>
                                {formik.values.tipo == 'Estandar' &&
                                    <Tab eventKey="inventario" title="Inventario">
                                        <InventarioProducto productoId={params.id} />
                                    </Tab>
                                }
                                {formik.values.tipo == 'Combinacion' &&
                                    <Tab eventKey="combinacion" title="Combinaciones">
                                        <NuevaCombinacion productoId={params.id} />
                                    </Tab>
                                }

                            </Tabs>

                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Container>

    );

}

const mapStateToProps = (state) => {
    return {
        pos: state.pos,
        user: state.user,
        mesaSeleccionada: state.pos.mesaSeleccionada
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        seleccionarMesaActionProps: (data) => { dispatch(seleccionarMesaAction(data)) },
        loadingActionProps: (data) => { dispatch(loadingAction(data)) }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(VerProductoScreen);
import React from 'react';
import {
    Card, Col, Container,
    Row
} from "react-bootstrap";
import { connect } from 'react-redux';
import { loadingAction, seleccionarMesaAction } from '../../../redux/actions';
import { posService } from "../../../services";
import './mesas.css';
function MesasScreen(props) {

    const { user, pos, seleccionarMesaActionProps, loadingActionProps } = props;
    const [mesas, setMesas] = React.useState(null);

    React.useEffect(() => {
        getMesas();
    }, []);

    const getMesas = async () => {

        try {
            loadingActionProps(true)
            const result = await posService.ObtenerZonas();
            setMesas(result);
            loadingActionProps(false)
        } catch (error) {
            loadingActionProps(false)
        }

    }

    const handleSeleccionarMesa = async (mesaId) => {
        const payload = {
            numero: 1,
            cajaId: pos.cajaId,
            mesaId: mesaId,
            usuarioId: user.data.UsuarioID,
            propina: false,
        }
        const result = await posService.seleccionarMesa(payload);
        console.log('mostrando result', result);
        seleccionarMesaActionProps({ mesaSeleccionada: mesaId, mesaAtencionId: result.id })
    }

    return (
        <Container>
            <Row>
                {mesas && mesas.map((data, index) => {
                    return (
                        <Col lg={12} key={index}>
                            <h3>{data.nombre}</h3>
                            <Row>
                                {data.mesas.map((dataMesas, index) => {
                                    const numberRandom = Math.floor(Math.random() * 3);
                                    const tablePic = require(`../../../assets/img/tableB${numberRandom}.svg`);
                                    return (
                                        <Col lg={2} sm={4} key={index}>
                                            <Card className={`p-2 mb-3 mesas-card pointer ${dataMesas.estado ? 'activeTable' : null}`} onClick={() => handleSeleccionarMesa(dataMesas.id)}>
                                                {dataMesas.estado
                                                    ?
                                                    <div className="text-center">
                                                        Atendido a las : {dataMesas.hora.hours}:{dataMesas.hora.minutes}
                                                    </div>
                                                    :
                                                    <div className="text-center">
                                                        Mesa Libre
                                                    </div>
                                                }

                                                <Card.Img variant="top" src={tablePic} />
                                                <Card.Body className="text-center">
                                                    {dataMesas.nombre}
                                                </Card.Body>
                                            </Card>
                                        </Col>
                                    )
                                })}
                            </Row>

                        </Col>
                    )
                })}

            </Row>
        </Container>
    )
}

const mapStateToProps = (state) => {
    return {
        pos: state.pos,
        user: state.user,
        mesaSeleccionada: state.pos.mesaSeleccionada
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        seleccionarMesaActionProps: (data) => { dispatch(seleccionarMesaAction(data)) },
        loadingActionProps: (data) => { dispatch(loadingAction(data)) }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(MesasScreen);
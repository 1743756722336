
import React from "react";
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import * as XLSX from "xlsx";
import { productosService } from "../../../services";
function ImportarProductos(props) {

    const { user } = props;
    const navigate = useNavigate();

    const [step, setStep] = React.useState(1)
    const [errors, setErrors] = React.useState({})
    const [dictionary, setDictionary] = React.useState([])
    const [colsModel, setColsModel] = React.useState([
        { name: "Id", value: "Id" },
        { name: "Codigo Producto", value: "codigoProducto", required: true },
        { name: "Nombre", value: "nombre", required: true },
        { name: "Categoria Id", value: "categoriaId", required: true },
        { name: "Precio de Compra", value: "precioCompra", required: true },
        { name: "Iva de Compra", value: "ivaCompra" },
        { name: "Precio de Venta", value: "precioVenta", required: true },
        { name: "Descripcion", value: "descripcion" },
        { name: "Foto", value: "foto" },
        { name: "Tipo", value: "tipo", required: true },
        { name: "UnidadMedida", value: "unidadMedida", required: true },
        { name: "Estado", value: "estado", required: true }
    ])
    const [tableHead, setTableHead] = React.useState(null)
    const [tableBody, setTableBody] = React.useState(null)
    const [categorias, setCategorias] = React.useState(null);

    const [tableHeadMaped, setTableHeadMaped] = React.useState(null)
    const [tableBodyMaped, setTableBodyMaped] = React.useState(null)

    const getCategorias = async () => {
        const result = await productosService.ObtenerCategorias();
        setCategorias(result);
    }

    const readExcel = (file) => {
        const promise = new Promise((resolve, reject) => {
            const fileReader = new FileReader();
            fileReader.readAsArrayBuffer(file);

            fileReader.onload = (e) => {
                const bufferArray = e.target.result;
                const wb = XLSX.read(bufferArray, { type: "buffer" });
                const wsname = wb.SheetNames[0];
                const ws = wb.Sheets[wsname];
                const data = XLSX.utils.sheet_to_json(ws);
                resolve(data);
            };
            fileReader.onerror = (error) => {
                reject(error);
            };
        });

        promise.then((d) => {
            const keys = Object.keys(d[0]).map((data) => data)
            console.log("mostrando keys", keys);
            console.log("mostrando values", d);
            setTableHead(keys)
            setTableBody(d)
        });
    };

    const handleChangeFile = (e) => {
        const file = e.target.files[0];
        readExcel(file);
        setStep(2)
    }

    const handleChangeOption = (e, data, index) => {
        const column = e.target.value;
        //Comprobar que exista
        if (dictionary && dictionary[index]?.dataHead == data) {
            const temp = [...dictionary];
            temp[index] = { indexHead: index, dataHead: data, dataModel: column };
            setDictionary(temp);
            return;
        }
        setDictionary([...dictionary, { indexHead: index, dataHead: data, dataModel: column }])
    }

    const createObject = async () => {
        //Recorrer ciclo for
        let tmpData = [];
        tableBody && tableBody.forEach((dataTableBody) => {
            let tmp = {};
            dictionary.forEach((dataDictionary) => {
                tmp = { ...tmp, [dataDictionary.dataModel]: dataTableBody[dataDictionary.dataHead] }
            })
            tmpData.push(tmp)
        })
        console.log("mostrando result data", tmpData);
        setTableHeadMaped(Object.keys(tmpData[0]).map((data) => data))
        setTableBodyMaped(tmpData);
        const result = await validarCols(Object.keys(tmpData[0]).map((data) => data));
        if (result.passed) {
            setStep(3);
            setErrors({})
        } else {
            setErrors({ message: 'Es necesario asociar los siguientes campos', cols: result.cols })
            console.log(result.cols);
        }

    }

    const handleInsertCategorias = () => {
        const tableBodyMapedd = [...tableBodyMaped];
        let dataMaped = tableBodyMapedd.map((data) => {
            let cat;
            cat = categorias.find(dataCat => dataCat.nombre == data.categoriaId);
            console.log("mostrando cat id", cat?.id);
            data.categoriaId = cat ? cat.id : null;
            return data;
        });
        setTableBodyMaped(dataMaped)
        console.log("mostrando data maped", dataMaped);
    }

    const handleChangeCategoria = (e, index) => {
        const value = e.target.value
        const temp = [...tableBodyMaped];
        console.log("mostrando temp", temp);
        //categoriaId
        temp[index].categoriaId = value;
        setTableBodyMaped(temp);
    }

    const validarCols = async (head) => {
        let cols = [];
        let passed = true;
        for (let index = 0; index < colsModel.length; index++) {
            const element = colsModel[index];
            if (element.required) {
                if (!head.includes(element.value)) {
                    cols.push(element.name);
                    passed = false;
                }
            }
        }
        return { passed, cols };
    }

    const validarCategorias = async () => {
        let passed = true;
        for (let index = 0; index < tableBodyMaped.length; index++) {
            const element = tableBodyMaped[index];
            if (!element.categoriaId) {
                passed = false;
            }
        }
        return passed;
    }

    const handleFinalizar = async () => {
        console.log("mostrando temp", tableBodyMaped);
        const result = await validarCategorias();
        if (result) {
            setErrors({})
            const data = [...tableBodyMaped];
            const payload = data.map((data) => {
                data.codigoProducto = data.codigoProducto.toString();
                data.tenantId = user.data.TenantId;
                data.estado = data.estado == 1 ? true : false
                return data;
            })
            try {
                const result = await productosService.CrearProductos(payload);
                Swal.fire({ icon: 'success', title: 'Éxito: ', text: 'Productos importados con éxito' });
                setTimeout(() => {
                    navigate('/productos');
                }, 500)

            } catch (error) {
                Swal.fire({ icon: 'error', title: 'Error: ', text: 'Se presentó un error' });
            }

        } else {
            setErrors({ message: 'Faltan categorías por asignar', cols: [] })
        }
    }

    React.useEffect(() => {
        console.log("mostrando dictionary", dictionary)
    })

    React.useEffect(() => {
        getCategorias();
    }, [])

    React.useEffect(() => {
        if (step == 3) {
            handleInsertCategorias();
        }

    }, [step])

    return (
        <div className="container">
            <div className="row">
                <>
                    {Object.keys(errors).length > 0 &&
                        <>
                            <div className="mt-3 mb-3 alert alert-danger">
                                {errors.message} <br />
                                {errors.cols.toString()}
                            </div>
                        </>
                    }
                    {step == 1 &&
                        <div className="col">
                            <h3>Sube el archivo en formado CSV o XLS</h3>
                            <div className="mb-3">
                                <label>Subir archivo</label>
                                <input type="file" onChange={(e) => handleChangeFile(e)} className="form-control"></input>
                            </div>
                        </div>
                    }
                    {step == 2 &&
                        <>
                            <div className="row">
                                <div className="col">
                                    <h3>Asigna las columnas a importar</h3>
                                    {tableHead && tableHead.map((data, indexHead) => {
                                        return (
                                            <div key={indexHead}>
                                                <div className="row mb-3">
                                                    <div className="col">
                                                        <strong>{data}</strong>
                                                    </div>
                                                    <div className="col">
                                                        <select className="form-control" onChange={(e) => handleChangeOption(e, data, indexHead)} >
                                                            <option>No Importar</option>
                                                            {colsModel && colsModel.map((data, indexModel) => {
                                                                return (
                                                                    <option key={indexModel} value={data.value}>{data.name}</option>
                                                                )
                                                            })}
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })}
                                </div>

                            </div>
                            <div className="row">
                                <div className="col">
                                    <button type="button" className="btn btn-success" onClick={() => createObject()}>Siguiente</button>
                                </div>
                            </div>
                        </>
                    }
                    {step == 3 &&
                        <>
                            <div className="row">
                                <div className="col">
                                    <h3>Vista previa de la información</h3>
                                    <div className="row">
                                        <div className="col">
                                            <table className="table">
                                                <thead>
                                                    <tr>
                                                        {tableHeadMaped && tableHeadMaped.map((data, index) => {
                                                            return (
                                                                <th key={index}>{data}</th>
                                                            )
                                                        })}
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {tableBodyMaped && tableBodyMaped.map((dataBody, indexTableBody) => {
                                                        return (
                                                            <tr key={indexTableBody}>
                                                                {tableHeadMaped && tableHeadMaped.map((dataHead, indexTableHead) => {
                                                                    return (
                                                                        <td key={indexTableHead}>
                                                                            {dataHead == 'categoriaId'
                                                                                ?
                                                                                <>
                                                                                    <select className={`form-control ${dataBody[dataHead] ? '' : 'is-invalid'} `} disabled={dataBody[dataHead] ? true : false} onChange={(e) => handleChangeCategoria(e, indexTableBody)} value={dataBody[dataHead]}>
                                                                                        <option>Elegir Categoria</option>
                                                                                        {categorias && categorias.map((dataCategorias, indexCategorias) => {
                                                                                            return (
                                                                                                <option value={dataCategorias.id} key={indexCategorias}>{dataCategorias.nombre}</option >
                                                                                            )
                                                                                        })}
                                                                                    </select>
                                                                                </>
                                                                                :
                                                                                <>
                                                                                    {dataBody[dataHead]}
                                                                                </>
                                                                            }
                                                                        </td>
                                                                    )
                                                                })}
                                                            </tr>
                                                        )
                                                    })}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                    {step == 1 || step == 2 &&
                                        <button type="button" className="btn btn-success" onClick={() => createObject()}>Siguiente</button>
                                    }
                                    {step == 3 &&
                                        <button type="button" className="btn btn-success" onClick={() => handleFinalizar()}>Finalizar</button>
                                    }

                                </div>
                            </div>
                        </>
                    }
                    {Object.keys(errors).length > 0 &&
                        <>
                            <div className="mt-3 mb-3 alert alert-danger">
                                {errors.message} <br />
                                {errors.cols.toString()}
                            </div>
                        </>
                    }
                </>

            </div>

            {/* <div className="row">
                <div className="col">
                    <table className="table">
                        <thead>
                            <tr>
                                {tableHead && tableHead.map((data,index)=>{
                                    return(
                                        <th key={index}>{data}</th>
                                    )
                                })}
                            </tr>
                        </thead>
                        <tbody>
                            {tableBody && tableBody.map((dataBody,index)=>{
                                return(
                                    <tr key={index}>
                                        {tableHead && tableHead.map((dataHead,index)=>{
                                            return(
                                            <td key={index}>
                                                {dataBody[dataHead]}
                                            </td>
                                            )
                                        })}
                                    </tr>
                                )
                                })}
                        </tbody>
                    </table>
                </div>
            </div> */}
        </div>
    )

}

const mapStateToProps = (state) => {
    return {
        pos: state.pos,
        user: state.user,
        mesaSeleccionada: state.pos.mesaSeleccionada
    }
};


export default connect(mapStateToProps, null)(ImportarProductos);
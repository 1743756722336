import  { fetch }  from '../../utils/fetch'

const GetMesas = async() => {
    
  try {
      const { data } = await fetch('mesa', 'get')
      return data
    } catch (e) {
      return Promise.reject(e)
    }
}

const GetMesasById = async(id) => {
    
    try {
        const { data } = await fetch('mesa/'+id, 'get')
        return data
      } catch (e) {
        return Promise.reject(e)
      }
  }

  const CreateMesas = async(payload) => {
    
    try {
        const { data } = await fetch('mesa', 'post', payload)
        return data
      } catch (e) {
        return Promise.reject(e)
      }
  }

  const UpdateMesas = async(id,payload) => {
    
    try {
        const { data } = await fetch('mesa/'+id, 'put', payload)
        return data
      } catch (e) {
        return Promise.reject(e)
      }
  }

export const mesasService = {
    GetMesas,
    GetMesasById,
    CreateMesas,
    UpdateMesas
}
import { fetch } from '../../utils/fetch'

const CrearProducto = async (payload) => {

  try {
    const { data } = await fetch('productos', 'post', payload)
    return data
  } catch (e) {
    return Promise.reject(e)
  }
}

const CrearProductos = async (payload) => {

  try {
    const { data } = await fetch('productos/bulk', 'post', payload)
    return data
  } catch (e) {
    return Promise.reject(e)
  }
}

const CrearCombinacion = async (payload) => {

  try {
    const { data } = await fetch('productos/combinacion', 'post', payload)
    return data
  } catch (e) {
    return Promise.reject(e)
  }
}

const ObtenerCombinacion = async (id) => {

  try {
    const { data } = await fetch('productos/combinacion/' + id, 'get')
    return data
  } catch (e) {
    return Promise.reject(e)
  }
}

const ActualizarProducto = async (id, payload) => {

  try {
    const { data } = await fetch('productos/' + id, 'put', payload)
    return data
  } catch (e) {
    return Promise.reject(e)
  }
}

const CrearMovimientoInventario = async (payload) => {

  try {
    const { data } = await fetch('movimientos/', 'post', payload)
    return data
  } catch (e) {
    return Promise.reject(e)
  }
}

const ObtenerMovimientoInventario = async (id) => {

  try {
    const { data } = await fetch('movimientos/' + id, 'get')
    return data
  } catch (e) {
    return Promise.reject(e)
  }
}

const CrearCategoria = async (payload) => {

  try {
    const { data } = await fetch('categorias', 'post', payload)
    return data
  } catch (e) {
    return Promise.reject(e)
  }
}

const ObtenerProductos = async () => {

  try {
    const { data } = await fetch('productos', 'get')
    return data
  } catch (e) {
    return Promise.reject(e)
  }
}

const ObtenerProductosById = async (id) => {

  try {
    const { data } = await fetch('productos/' + id, 'get')
    return data
  } catch (e) {
    return Promise.reject(e)
  }
}

const ObtenerCategorias = async () => {

  try {
    const { data } = await fetch('categorias', 'get')
    return data
  } catch (e) {
    return Promise.reject(e)
  }
}

const ObtenerInventarioById = async (id) => {

  try {
    const { data } = await fetch('movimientos/inventario/' + id, 'get')
    return data
  } catch (e) {
    return Promise.reject(e)
  }
}

const ObtenerMovimientos = async (id) => {

  try {
    const { data } = await fetch('movimientos', 'get')
    return data
  } catch (e) {
    return Promise.reject(e)
  }
}

const ObtenerMovimientosById = async (id) => {

  try {
    const { data } = await fetch('movimientos/' + id, 'get')
    return data
  } catch (e) {
    return Promise.reject(e)
  }
}

const ObtenerProductosByTipo = async () => {

  try {
    const { data } = await fetch('productos/tipo/Estandar', 'get')
    return data
  } catch (e) {
    return Promise.reject(e)
  }
}

export const productosService = {
  ObtenerProductos,
  CrearProducto,
  CrearProductos,
  ObtenerCategorias,
  CrearCategoria,
  ObtenerProductosById,
  ActualizarProducto,
  ObtenerInventarioById,
  ObtenerMovimientos,
  ObtenerMovimientosById,
  ObtenerProductosByTipo,
  CrearMovimientoInventario,
  ObtenerMovimientoInventario,
  CrearCombinacion,
  ObtenerCombinacion
}
import { useFormik } from 'formik';
import React from "react";
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import loginBg from '../../assets/img/login/login-bg.jpeg';
import logoSmall from '../../assets/img/logo-small.png';
import { loginModel } from '../../models';
import { loadingAction, loginAction } from '../../redux/actions';
import { loginService } from '../../services';
import './login.css';
function Login(props) {
  const { loginActionProps, loadingActionProps } = props;

  React.useEffect(() => {
    document.title = "Login - MiPosDigital";
  }, []);

  const navigate = useNavigate();
  const [loading, setLoading] = React.useState(null);
  const [tenantId, setTenantId] = React.useState(null);
  const [tenantData, setTenantData] = React.useState(null);

  const submit = async (payload) => {
    loadingActionProps(true);
    setLoading(true)
    localStorage.clear();
    payload.TenantId = tenantData.id;
    try {
      const result = await loginService.Login(payload);
      localStorage.setItem('token', result);
      loginActionProps(result);
      loadingActionProps(false);
      setLoading(false)
      navigate("/pos", { replace: true });

    } catch (error) {
      loadingActionProps(false);
      setLoading(false)
      Swal.fire({ icon: 'error', title: 'Error: ' + error.response.status, text: error.response.data.message })
    }

  }

  const { handleSubmit, handleChange } = useFormik({
    initialValues: loginModel,
    onSubmit: values => submit(values)
  });

  const submitTenantId = async () => {
    setLoading(true)
    try {
      const result = await loginService.CheckTenantName(tenantId);
      console.log(result);
      setTenantData(result);
      setLoading(false)
    } catch (error) {
      setLoading(false)
      Swal.fire({ icon: 'error', title: 'Error: ' + error.response.status, text: error.response.data.message })
    }


  }

  return (
    <div id="root" className="h-full flex grow bg-slate-50 " x-cloak>
      <div className="fixed top-0 hidden p-6 lg:block lg:px-12">
        <a href="#" className="flex items-center space-x-2">
          <img className="h-100 w-20" src={logoSmall} alt="logo" />
        </a>
      </div>
      <div className="hidden w-full h-screen place-items-center lg:grid">
        <img className="object-cover w-full h-full" src={loginBg} alt="image" />
      </div>
      <main className="flex w-full flex-col items-center bg-white dark:bg-navy-700 lg:max-w-md">
        <div className="flex w-full max-w-sm grow flex-col justify-center p-5">
          <div className="text-center">
            <img className="mx-auto h-20 w-auto" src={logoSmall} alt="logo" />
            <div className="mt-4">
              <h2 className="text-2xl font-semibold text-slate-600 dark:text-navy-100">
                MiPos Digital
              </h2>
            </div>
            {tenantData &&
              <p>
                Bienvenido de nuevo a {tenantData.tenantName}
              </p>
            }

          </div>
          {!tenantData &&
            <>
              <div className="mt-16">
                <label className="relative flex">
                  <input
                    className="form-input peer w-full rounded-lg bg-slate-150 px-3 py-2 pl-9 ring-primary placeholder:text-slate-400 hover:bg-slate-200 focus:ring /90 dark:ring-accent dark:placeholder:text-navy-300 dark:hover:bg-navy-900 dark:focus:bg-navy-900"
                    name="tenantName"
                    onBlur={(e) => setTenantId(e.target.value)}
                    placeholder="ID Cliente"
                    type="text"
                  />
                </label>
                <button onClick={() => submitTenantId()} className="btn mt-10 h-10 w-full bg-primary font-medium text-white hover:bg-primary-focus focus:bg-primary-focus active:bg-primary-focus/90 dark:bg-accent dark:hover:bg-accent-focus dark:focus:bg-accent-focus dark:active:bg-accent/90">
                  {loading ? 'Buscando...' : 'Buscar'}
                </button>
                {/* <div className="mt-4 text-center text-xs+">
              <p className="line-clamp-1">
                <span>No eres cliente aún? </span>
                <a className="text-primary transition-colors hover:text-primary-focus dark:text-accent-light dark:hover:text-accent" href="pages-singup-2.html"> Regístrate </a>
              </p>
            </div> */}
              </div>
            </>
          }
          {tenantData &&
            <form onSubmit={handleSubmit}>
              <div className="mt-16">
                <label className="relative flex">
                  <input
                    type="text"
                    name="usuario"
                    onChange={handleChange}
                    className="form-input peer w-full rounded-lg bg-slate-150 px-3 py-2 pl-9 ring-primary placeholder:text-slate-400 hover:bg-slate-200 focus:ring /90 dark:ring-accent dark:placeholder:text-navy-300 dark:hover:bg-navy-900 dark:focus:bg-navy-900"
                    placeholder="Usuario"
                  />

                </label>
                <label className="relative mt-4 flex">
                  <input
                    type="password"
                    name="clave"
                    onChange={handleChange}
                    className="form-input peer w-full rounded-lg bg-slate-150 px-3 py-2 pl-9 ring-primary placeholder:text-slate-400 hover:bg-slate-200 focus:ring /90 dark:ring-accent dark:placeholder:text-navy-300 dark:hover:bg-navy-900 dark:focus:bg-navy-900"
                    placeholder="Clave"
                  />

                </label>
                <button type="submit" className="btn mt-10 h-10 w-full bg-primary font-medium text-white hover:bg-primary-focus focus:bg-primary-focus active:bg-primary-focus/90 dark:bg-accent dark:hover:bg-accent-focus dark:focus:bg-accent-focus dark:active:bg-accent/90">
                  {loading ? 'Cargando...' : 'Entrar'}
                </button>
                {/* <div className="mt-4 text-center text-xs+">
              <p className="line-clamp-1">
                <span>No eres cliente aún? </span>
                <a className="text-primary transition-colors hover:text-primary-focus dark:text-accent-light dark:hover:text-accent" href="pages-singup-2.html"> Regístrate </a>
              </p>
            </div> */}
              </div>
            </form>
          }

        </div>
      </main>
    </div>

  );
}
const mapDispatchToProps = (dispatch) => {
  return {
    loginActionProps: (data) => { dispatch(loginAction(data)) },
    loadingActionProps: (data) => { dispatch(loadingAction(data)) },
  }
};

export default connect(null, mapDispatchToProps)(Login);

import React from 'react';
import Chart from 'react-apexcharts';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { AlertConfirm } from '../../components/alert';
import ModalComponent from '../../components/modal';
import VerCierreCajaComponent from '../../components/ver-cierre-caja';
import { cerrarCajaAction, loadingAction } from "../../redux/actions";
import { dashboardService, posService } from '../../services';
import './dashboard.scss';



function Dashboard({ user, pos, cerrarCajaActionProps }) {



    const [arqueo, setArqueo] = React.useState(null);
    const [cajaId, setCajaId] = React.useState(null);

    const [cierresCaja, setCierresCaja] = React.useState(null);
    const [showCierreCaja, setShowCierreCaja] = React.useState(false);
    const [ventasAnuales, setVentasAnuales] = React.useState(null);
    const navigate = useNavigate();

    const series = [arqueo?.efectivo, arqueo?.tarjetas, arqueo?.transferencia]

    const options = {
        chart: {
            width: 380,
            type: 'donut',
        },
        labels: ['Efectivo', 'Tarjetas', 'Transferencia'],
        colors: ['#1ea0df', '#4b5260', '#030706'],
        plotOptions: {
            pie: {
                startAngle: -90,
                endAngle: 270
            }
        },
        dataLabels: {
            enabled: false
        },
        fill: {
            type: 'gradient',
        },
        legend: {
            formatter: function (val, opts) {
                return val + " - " + opts.w.globals.series[opts.seriesIndex]
            }
        },
        title: {
            text: 'Comportamiento de las ventas'
        },
        responsive: [{
            breakpoint: 480,
            options: {
                chart: {
                    width: 200
                },
                legend: {
                    position: 'bottom'
                }
            }
        }]
    }

    const options2 = {
        chart: {
            type: 'bar',
            height: 350
        },
        plotOptions: {
            bar: {
                horizontal: false,
                columnWidth: '55%',
                endingShape: 'rounded'
            },
        },
        dataLabels: {
            enabled: false
        },
        stroke: {
            show: true,
            width: 2,
            colors: ['transparent']
        },
        xaxis: {
            categories: ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'],
        },
        yaxis: {
            title: {
                text: '$ (Pesos)'
            }
        },
        fill: {
            opacity: 1
        },
        tooltip: {
            y: {
                formatter: function (val) {
                    return "$ " + val.toLocaleString('es-Es') + " Pesos"
                }
            }
        }
    }



    React.useEffect(() => {
        document.title = "Dashboard - MiPosDigital"
        if (pos.cajaId) {
            getArqueo();
        }
        getCajas();
        getVentasAnuales();
    }, [])

    const getArqueo = async () => {
        try {
            const result = await posService.ObtenerArqueoCaja(pos.cajaId);
            setArqueo(result);
            console.log("mostrando result", result);

        } catch (error) {
            console.log("mostrando error", error);
        }
    }

    const getCajas = async () => {
        try {
            const result = await posService.ObtenerCajas(pos.cajaId);
            setCierresCaja(result);
            console.log("mostrando result", result);

        } catch (error) {
            console.log("mostrando error", error);
        }
    }

    const getVentasAnuales = async () => {
        const result = await dashboardService.VentasAnuales();
        setVentasAnuales(result);

    }

    const handleViewCierreCaja = (id) => {
        console.log("mostrando id de caja", id)
        setCajaId(id);
        setShowCierreCaja(true);
    }


    const cerrarCaja = async () => {

        AlertConfirm('Está seguro de cerrar caja ?', 'question', async (response) => {
            if (response.isConfirmed) {
                try {
                    setCajaId(pos.cajaId);
                    const payload = {
                        "usuarioCierraId": user.data.UsuarioID,
                        "totalVendido": arqueo.totalVendido,
                        "efectivo": arqueo.efectivo,
                        "tarjetas": arqueo.tarjetas,
                        "totalRecaudo": arqueo.totalRecaudo,
                        "totalPropinas": arqueo.totalPropinas,
                        "transferencia": arqueo.transferencia,
                        "propinasEfectivo": arqueo.propinasEfectivo,
                        "propinasTarjetas": arqueo.propinasTarjetas,
                        "gastos": arqueo.gastos,
                    }
                    console.log("mostrando payload", payload);
                    const result = await posService.CerrarCajaFinal(pos.cajaId, payload);
                    console.log("mostrando result", result);

                    setShowCierreCaja(true);

                    //dispatch actions
                    cerrarCajaActionProps();

                    setArqueo(null);

                    //navigate
                    //navigate('/pos');

                } catch (error) {
                    console.log("mostrando error", error);
                }
            }
        })


    }

    return (
        <>


            <div className="container">
                {arqueo &&
                    <div className="row">
                        <div className="col-lg-12">
                            <div className='row mb-3'>
                                <div className="col">
                                    <h3>Hola este es el arqueo de caja abierto por {arqueo.usuarioAbre.nombreCompleto} el {new Date(arqueo.fechaApertura).toLocaleDateString('es-Es')}</h3>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col">
                                    <div className="row mb-3">
                                        <div className="col">
                                            <div className="card card-stats text-white">
                                                <div className="card-body">
                                                    <div className="row">
                                                        <i className="fa-regular fa-money-bill-1" />
                                                    </div>
                                                    <div className="row">
                                                        <h3 className="card-stats-money">{arqueo.dineroBase.toLocaleString('es')}</h3>
                                                    </div>
                                                    <div className="row">
                                                        <div className="card-stats-text">
                                                            Base Efectivo
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col">
                                            <div className="card card-stats text-white">
                                                <div className="card-body">
                                                    <div className="row">
                                                        <i className="fa-regular fa-money-bill-1" />
                                                    </div>
                                                    <div className="row">
                                                        <h3 className="card-stats-money">{arqueo.totalVendido.toLocaleString('es')}</h3>
                                                    </div>
                                                    <div className="row">
                                                        <div className="card-stats-text">
                                                            Total Ventas
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                    <div className='row mb-3'>
                                        <div className="col">
                                            <div className="card card-stats text-white">
                                                <div className="card-body">
                                                    <div className="row">
                                                        <i className="fa-regular fa-money-bill-1" />
                                                    </div>
                                                    <div className="row">
                                                        <h3 className="card-stats-money">{arqueo.totalPropinas.toLocaleString('es')}</h3>
                                                    </div>
                                                    <div className="row">
                                                        <div className="card-stats-text">
                                                            Total Propinas
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col">
                                            <div className="card card-stats text-white">
                                                <div className="card-body">
                                                    <div className="row">
                                                        <i className="fa-regular fa-money-bill-1" />
                                                    </div>
                                                    <div className="row">
                                                        <h3 className="card-stats-money">{arqueo.totalRecaudo.toLocaleString('es')}</h3>
                                                    </div>
                                                    <div className="row">
                                                        <div className="card-stats-text">
                                                            Total Recaudo
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col">
                                    <Chart options={options} series={series} type="donut" height={300} />
                                </div>
                            </div>


                            <div className="row">
                                <div className="col-lg-12">
                                    ¿Cómo cerrar caja? - Separa el dinero base, separa el dinero de las propinas, suma el efectivo + las tarjetas + las transferencias = debe ser igual al total de ventas
                                </div>
                            </div>

                            <div className="row mt-3 mb-3">
                                <div className="col-lg-12">
                                    <button type="button" onClick={() => cerrarCaja()} className="btn btn-secondary border-0">Cerrar Caja</button>
                                </div>
                            </div>

                        </div>
                    </div>
                }

                <div className="row">
                    <div className="col">
                        <h3>Histórico de ventas - anual</h3>
                        {ventasAnuales &&
                            <Chart options={options2} series={ventasAnuales} type="bar" height={300} />
                        }

                    </div>
                </div>
                {cierresCaja &&
                    <>
                        <div className="row">
                            <div className="col-lg-12">
                                <h3>Cierres de caja recientes</h3>
                                <div className="table-responsive">
                                    <table className="table">
                                        <thead>
                                            <tr>
                                                <th>Apertura</th>
                                                <th>Cierre</th>
                                                <th>Total Vendido</th>
                                                <th>Total Propinas</th>
                                                <th>Total Recaudado</th>
                                                <th>Opciones</th>
                                            </tr>
                                            {cierresCaja && cierresCaja.map((data, index) => {
                                                return (
                                                    <tr key={index}>
                                                        <td>{new Date(data.fechaApertura).toLocaleString('es-Es')}</td>
                                                        <td>{new Date(data.fechaCierre).toLocaleString('es-Es')}</td>
                                                        <td>{data.totalVendido.toLocaleString('es-Es')}</td>
                                                        <td>{data.totalPropinas.toLocaleString('es-Es')}</td>
                                                        <td>{(parseFloat(data.totalVendido) + parseFloat(data.totalPropinas)).toLocaleString('es-Es')}</td>
                                                        <td><button type="button" onClick={() => handleViewCierreCaja(data.id)} className="btn btn-secondary border-0 btn-xs" ><i className="fa fa-eye"></i></button></td>
                                                    </tr>
                                                )
                                            })}

                                        </thead>
                                    </table>
                                </div>

                            </div>
                        </div>
                    </>
                }

            </div>

            <ModalComponent isShowing={showCierreCaja} setIsShowing={setShowCierreCaja} title="Cierre de caja">
                <VerCierreCajaComponent cajaId={cajaId} />
            </ModalComponent>


        </>
    )
}

const mapStateToProps = (state) => {
    return {
        pos: state.pos,
        user: state.user,
        mesaSeleccionada: state.pos.mesaSeleccionada
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        loadingActionProps: (data) => { dispatch(loadingAction(data)) },
        cerrarCajaActionProps: () => { dispatch(cerrarCajaAction()) }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);



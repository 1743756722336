import { useFormik } from 'formik';
import React from 'react';
import {
    Card, Col, Container, Form, Row
} from "react-bootstrap";
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import { loadingAction } from '../../../redux/actions';
import { zonasService } from '../../../services';
function NuevaZonaScreen(props) {

    const { loadingActionProps } = props;
    const navigate = useNavigate();

    const formik = useFormik({
        initialValues: {
            "nombre": ""
        },
        enableReinitialize: true,
        onSubmit: values => {
            submit(values);
        }
    })

    const submit = async (params) => {
        loadingActionProps(true);

        const payload = params
        try {
            await zonasService.CreateZonas(payload);
            loadingActionProps(false);
            Swal.fire({ icon: 'success', title: 'Éxito: ', text: 'Zona creada con éxito' })
            setTimeout(() => { navigate('/zonas') }, 500)
        } catch (error) {
            Swal.fire({ icon: 'error', title: 'Ops...: ', text: 'Error al crear la zona' })
            loadingActionProps(false);
        }

    }

    React.useEffect(() => {
        document.title = "Nueva Zona"
    }, [])

    return (
        <>
            <Container fluid>
                <Row className="p-4">
                    <Col lg={12}>
                        <h2>Nueva Zona</h2>
                    </Col>
                </Row>
                <Row>
                    <Col lg={12}>
                        <Card>
                            <Card.Body>
                                <Form onSubmit={formik.handleSubmit}>
                                    <Row>
                                        <Col>
                                            <div className="mb-3">
                                                <label>Nombre</label>
                                                <input type="text" className="form-control" name="nombre" defaultValue={formik.values.nombre} onChange={formik.handleChange} onBlur={formik.handleBlur} />
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <button type="submit" className="btn btn-primary">Guardar</button>
                                        </Col>
                                    </Row>
                                </Form>

                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </>
    )
}
const mapStateToProps = (state) => {
    return {
        pos: state.pos,
        user: state.user
    }
};

const mapDispatchToProps = (dispatch) => {
    return {

        loadingActionProps: (data) => { dispatch(loadingAction(data)) }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(NuevaZonaScreen);
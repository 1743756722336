import React from "react";
import { Nav, Navbar, Container, Row, Col, NavDropdown } from "react-bootstrap";
import { Link, Outlet } from "react-router-dom";
import HeaderComponent from "../../components/header";
import LoadingOverlay from 'react-loading-overlay';
import { useSelector } from "react-redux";
import Sidebar from "../../components/sidebar";
import './default.scss'
function DefaultLayout() {

  const loading = useSelector((state) => state.loading);
  console.log("mostrando loading", loading);

  return (
    <>
    <main id="wrapper" className="d-flex">
      {/* Header */}
      <HeaderComponent/>
      {/* Sidebar */}
      <Sidebar />
      {/* Body */}
      <div className="content-body col">
        <LoadingOverlay active={loading} text='Cargando....'>
            <Container fluid>
              <Row className="mt-2">
                <Col>
                  <Outlet />
                </Col>
              </Row>
            </Container>
          </LoadingOverlay>
      </div>
      
    </main>
    
    
    </>
    
  );
}

export default DefaultLayout;

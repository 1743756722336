import { Field, FieldArray, Form, Formik } from "formik";
import React from 'react';
import { connect } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import Swal from 'sweetalert2';
import { abrirCajaAction, loadingAction } from "../../redux/actions";
import { posService } from '../../services';
function ProcesarVentaComponent(props) {

  const { handleCloseTableAfterProcessBill, loadingActionProps, setShowModalFactura, setFacturaResponse, setIsShowing, payloadToProcessSale } = props;
  console.log("Mostrando payload en componente procesar venta", payloadToProcessSale)

  const initialValues = {
    pagos: [
      {
        metodo: "Efectivo",
        monto: 0
      },
    ],
    origenPropina: null,
  };

  const handleProcesarVenta = async (values) => {
    loadingActionProps(true)
    if(values.origenPropina == null || values.origenPropina == ''){
      Swal.fire({ icon: 'error', title: 'Debe elegir el origen de la propina' })
      return
    }
    let payload = payloadToProcessSale;
    payload.origenPropina = values.origenPropina;
    payload.pagos = values.pagos.map((data, index) => {
      return {
        tenantId: payloadToProcessSale.tenantId,
        metodo: data.metodo,
        monto: data.monto,
        numeroAutorizacion: "",
        usuarioId: payloadToProcessSale.usuarioCreaId,
      }
    })
    try {
      setIsShowing(false);
      const result = await posService.ProcesarVenta(payload);
      Swal.fire({ icon: 'success', title: 'Éxito: ', text: 'Factura Creado con Éxito' })
      setFacturaResponse(result);
      setShowModalFactura(true);
      loadingActionProps(false);
      handleCloseTableAfterProcessBill();
    } catch (error) {
      Swal.fire({ icon: 'error', title: 'Error: ' + error.response.status, text: error.response.data.message })
      loadingActionProps(false)
    }

  }

  const metodoTarjetaPresente = (values) => {
    return values.pagos.find(x => x.metodo.includes('Tarjeta')) !== undefined;
  } 

  return (
    <>
      <ToastContainer />
      {/* Formik */}
      <Formik
        initialValues={initialValues}
        onSubmit={async (values) => {
          handleProcesarVenta(values)
        }}
      >
        {({ values, handleChange }) => (
          <Form>
            <FieldArray name="pagos">
              {({ insert, remove, push, key, index }) => (
                <div key={key}>
                  <table className="table">
                    <thead>
                      <tr>
                        <th>Metodo</th>
                        <th>Monto</th>
                        <th>
                          <button
                            type="button"
                            className="btn btn-primary"
                            onClick={() => push({ metodo: "Efectivo", monto: '' })}
                          >
                            Agregar Pago
                          </button>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {values.pagos.length > 0 &&
                        values.pagos.map((data, index) => (
                          <tr key={index}>
                              <td>
                                <select
                                  className="form-control"
                                  name={`pagos.${index}.metodo`}
                                  onChange={handleChange}
                                  defaultValue={values.pagos[index].metodo}
                                >
                                  <option value="Efectivo">Efectivo</option>
                                  <option value="Tarjeta">Tarjeta</option>
                                </select>
                              </td>
                              <td>
                                <input
                                  type="number"
                                  className="form-control"
                                  placeholder="Monto"
                                  name={`pagos.${index}.monto`}
                                  defaultValue={values.pagos[index].monto}
                                  onChange={handleChange}
                                />
                              </td>
                              <td>
                                {values.pagos.length > 1 &&
                                  <button
                                    type="button"
                                    className="btn btn-xs btn-danger"
                                    onClick={() => remove(index)}
                                  >
                                    X
                                  </button>
                                }

                              </td>
                            </tr>
                        ))}
                    </tbody>
                  </table>
                </div>
              )}
            </FieldArray>
            <div className="row">
              <div className="col">
                <div className="mb-3">
                  <label>Oriden de la propina</label>
                  <Field as='select' className='form-control' name="origenPropina">
                    <option value="">Seleccionar</option>
                    <option value="Efectivo">Efectivo</option>
                    {metodoTarjetaPresente(values) && <option value="Tarjeta">Tarjeta</option>}
                  </Field>
                </div>
              </div>
            </div>
            <table className="table">
              <thead>
                <tr>
                  <th>Total Items</th>
                  <th>{payloadToProcessSale.facturaItems.length}</th>
                </tr>
                <tr>
                  <th>Total a pagar</th>
                  <th>{payloadToProcessSale.grandTotal.toLocaleString('es-Es')}</th>
                </tr>
                <tr>
                  <th>Pagado</th>
                  <th>{values && values.pagos && values.pagos.length > 0 && (values.pagos.map((data) => data.monto).reduce((a, b) => a + b)).toLocaleString('es')}</th>
                </tr>
                <tr>
                  <th>Saldo</th>
                  <th>{values && values.pagos && values.pagos.length > 0 && (payloadToProcessSale.grandTotal - values.pagos.map((data) => data.monto).reduce((a, b) => a + b)).toLocaleString('es')}</th>
                </tr>
              </thead>
              <tbody>

              </tbody>
            </table>
            {payloadToProcessSale.grandTotal == (values.pagos.map((data) => data.monto).reduce((a, b) => a + b)) &&
              <button type="submit" className="btn btn-primary">Procesar Venta</button>
            }
          </Form>
        )}
      </Formik>
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    pos: state.pos,
    user: state.user,
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    abrirCajaActionProps: (data) => { dispatch(abrirCajaAction(data)) },
    loadingActionProps: (data) => { dispatch(loadingAction(data)) }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProcesarVentaComponent);

import { FieldArray, Form, Formik } from 'formik';
import React from 'react';
import {
    Card, Col, Container,
    Row
} from "react-bootstrap";
import AsyncSelect from 'react-select/async';
import Swal from 'sweetalert2';

import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { loadingAction } from '../../../redux/actions';
import { productosService } from '../../../services';

function NuevoMovimientoInventario(props) {

    const { user, loadingActionProps } = props;
    const navigate = useNavigate();

    React.useEffect(() => {
        getProductosEstandar()
    }, [])

    const getProductosEstandar = async () => {
        try {
            const result = await productosService.ObtenerProductosByTipo()
            const values = result.map((data) => {
                return {
                    label: data.nombre,
                    value: data.id
                }
            })
            console.log("mostrando values", values)
            return values;
        } catch (error) {

        }
    }

    const initialValues = {
        operacionId: null,
        tenantId: user.data.TenantId,
        categoria: 0,
        refDocumento: '',
        observaciones: '',
        movimientosItems: [
            {
                tenantId: user.data.TenantId,
                productoId: null,
                cantidad: 0
            }
        ]
    }

    const submit = async (values) => {
        loadingActionProps(true);
        try {
            const result = await productosService.CrearMovimientoInventario(values);
            loadingActionProps(false);
            Swal.fire({ icon: 'success', title: 'Éxito: ', text: 'Movimiento creado con éxito' })
            setTimeout(() => { navigate('/productos') }, 500)
        } catch (error) {
            Swal.fire({ icon: 'error', title: 'Ops...: ', text: 'Error al creaer el movimiento' })
            loadingActionProps(false);
        }

    }

    const handleInputChange = (e, name, setFieldValue) => {
        console.log("mostrando new value", e);
        console.log("mostrando name", name);
        setFieldValue(name, e.value)
    };

    const loadOptions = (inputValue) => {
        return new Promise((resolve, reject) => {
            resolve(getProductosEstandar())
        })
    }

    return (
        <Container fluid>
            <Row className="p-4">
                <Col lg={12}>
                    <h2>Nuevo Movimiento</h2>
                </Col>
            </Row>
            <Row>
                <Col lg={12}>
                    <Card>
                        <Card.Body>
                            <Formik
                                initialValues={initialValues}
                                onSubmit={async (values, { resetForm }) => {
                                    submit(values, resetForm);

                                }}
                            >
                                {({ values, handleChange, handleBlur, setFieldValue }) => (
                                    <Form>
                                        <Row>
                                            <Col>
                                                <div className="mb-3">
                                                    <label>Tipo de operacion</label>
                                                    <select className="form-control" name="operacionId" onChange={handleChange} value={values.operacionId}>
                                                        <option>Seleccionar Opcion</option>
                                                        <option value="04D31C61-866D-4F02-9A13-74E3D16C8D24">Entrada</option>
                                                        <option value="A59C3A64-38B2-4A2F-BBA4-9934516AB96B">Salida</option>
                                                    </select>
                                                </div>

                                            </Col>
                                            <Col>
                                                <div className="mb-3">
                                                    <label>Categoría</label>
                                                    <select className="form-control" name="categoria" onChange={handleChange} value={values.categoria}>
                                                        <option value="">Seleccionar Opcion</option>
                                                        <option value={0}>Compra</option>
                                                        <option value={1}>Devolucion</option>
                                                        <option value={2}>Ajuste</option>
                                                        <option value={3}>Inventario Inicial</option>
                                                    </select>
                                                </div>
                                            </Col>
                                        </Row>

                                        <Row>

                                        </Row>
                                        <Col>
                                            <label>Observaciones</label>
                                            <textarea name="observaciones" className="form-control" onChange={handleChange}></textarea>
                                        </Col>
                                        <div className="mt-3 mb-3">
                                            <h3>Productos</h3>
                                        </div>

                                        <FieldArray name="movimientosItems">
                                            {({ insert, remove, push }) => (
                                                <div>
                                                    {values.movimientosItems.length > 0 && values.movimientosItems.map((data, index) => (
                                                        <div className="row" key={index}>
                                                            <div className="col-5">
                                                                <label>Producto</label>
                                                                <AsyncSelect
                                                                    cacheOptions
                                                                    loadOptions={loadOptions}
                                                                    defaultOptions
                                                                    onChange={(e) => handleInputChange(e, `movimientosItems.${index}.productoId`, setFieldValue)}
                                                                />
                                                            </div>
                                                            <div className="col-5">
                                                                <label>Cantidad</label>
                                                                <input type="number" name={`movimientosItems.${index}.cantidad`} onChange={handleChange} className="form-control" />
                                                            </div>
                                                            <div className="col-2 d-flex align-items-end">
                                                                <button type="button" className="btn btn-danger" onClick={() => remove(index)}>x</button>
                                                            </div>


                                                        </div>
                                                    ))}
                                                    <button type="button" className="btn btn-secondary border-0 mt-3" onClick={() => push({ tenantId: user.data.TenantId, productoId: '', cantidad: '' })}>Agregar Producto</button>
                                                </div>
                                            )}
                                        </FieldArray>


                                        <Row className="text-right">
                                            <Col>
                                                <button type="submit" className="btn btn-primary border-0 mt-3">Finalizar Movimiento</button>
                                            </Col>
                                        </Row>


                                    </Form>
                                )}
                            </Formik>

                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Container>
    )
}

const mapStateToProps = (state) => {
    return {
        pos: state.pos,
        user: state.user,
        mesaSeleccionada: state.pos.mesaSeleccionada
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        loadingActionProps: (data) => { dispatch(loadingAction(data)) }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(NuevoMovimientoInventario);

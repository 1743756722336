import { Form, Formik } from 'formik';
import React from 'react';
import {
    Card, Col, Container,
    Row
} from "react-bootstrap";
import AsyncSelect from 'react-select/async';

import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import { loadingAction } from '../../../redux/actions';
import { productosService } from '../../../services';

function VerMovimientoInventario(props) {
    const params = useParams();
    const { id } = params;
    const { user } = props;
    const [initialValues, setInitialValues] = React.useState({});

    React.useEffect(() => {
        getProductosEstandar();
        getDetalleMovimientos();
    }, [])

    const getProductosEstandar = async () => {
        try {
            const result = await productosService.ObtenerProductosByTipo()
            const values = result.map((data) => {
                return {
                    label: data.nombre,
                    value: data.id
                }
            })
            console.log("mostrando values", values)
            return values;
        } catch (error) {

        }
    }

    const getDetalleMovimientos = async () => {
        try {
            const result = await productosService.ObtenerMovimientoInventario(id)
            const data = {
                operacionId: result.operacionId,
                tenantId: user.data.TenantId,
                categoria: result.categoria,
                refDocumento: result.refDocumento,
                observaciones: result.observaciones,
                movimientosItems: result.movimientosItems.map((data) => {
                    return {
                        tenantId: user.data.TenantId,
                        productoId: data.producto.id,
                        productoName: data.producto.nombre,
                        cantidad: data.cantidad
                    }
                })
            }
            setInitialValues(data)
            setTimeout(() => { console.log("mostrando initial data", data) }, 500)
            setTimeout(() => { console.log("mostrando initial values", initialValues) }, 500)

        } catch (error) {

        }
    }

    const handleInputChange = (e, name, setFieldValue) => {
        console.log("mostrando new value", e);
        console.log("mostrando name", name);
        setFieldValue(name, e.value)
    };

    const loadOptions = (inputValue) => {
        return new Promise((resolve, reject) => {
            resolve(getProductosEstandar())
        })
    }

    return (
        <Container fluid>
            <Row className="p-4">
                <Col lg={12}>
                    <h2>Ver Movimiento</h2>
                </Col>
            </Row>
            <Row>
                <Col lg={12}>
                    <Card>
                        <Card.Body>
                            <Formik
                                initialValues={initialValues}
                            >
                                {({ values, handleChange, handleBlur, setFieldValue }) => (
                                    <Form>
                                        <Row>
                                            <Col>
                                                <div className="mb-3">
                                                    <label>Tipo de operacion</label>
                                                    <select className="form-control" name="operacionId" value={initialValues.operacionId}>
                                                        <option>Seleccionar Opcion</option>
                                                        <option value="04d31c61-866d-4f02-9a13-74e3d16c8d24">Entrada</option>
                                                        <option value="a59c3a64-38b2-4a2f-bba4-9934516ab96b">Salida</option>
                                                    </select>
                                                </div>

                                            </Col>
                                            <Col>
                                                <div className="mb-3">
                                                    <label>Categoría</label>
                                                    <select className="form-control" name="categoria" value={initialValues.categoria}>
                                                        <option value="">Seleccionar Opcion</option>
                                                        <option value="0">Compra</option>
                                                        <option value="1">Devolucion</option>
                                                        <option value="2">Ajuste</option>
                                                        <option value="3">Inventario Inicial</option>
                                                    </select>
                                                </div>
                                            </Col>
                                        </Row>

                                        <Row>

                                        </Row>
                                        <Col>
                                            <label>Observaciones</label>
                                            <textarea name="observaciones" className="form-control" value={initialValues.observaciones}></textarea>
                                        </Col>
                                        <div className="mt-3 mb-3">
                                            <h3>Productos</h3>
                                        </div>

                                        <div>
                                            {initialValues.movimientosItems?.length > 0 && initialValues.movimientosItems.map((data, index) => (
                                                <div className="row" key={index}>
                                                    <div className="col-6">
                                                        <label>Producto</label>
                                                        <AsyncSelect
                                                            cacheOptions
                                                            loadOptions={loadOptions}
                                                            defaultOptions
                                                            value={{ label: data.productoName, value: data.productId }}

                                                        />
                                                    </div>
                                                    <div className="col-6">
                                                        <label>Cantidad</label>
                                                        <input type="number" value={data.cantidad} className="form-control" />
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    </Form>
                                )}
                            </Formik>

                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Container>
    )
}

const mapStateToProps = (state) => {
    return {
        pos: state.pos,
        user: state.user,
        mesaSeleccionada: state.pos.mesaSeleccionada
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        loadingActionProps: (data) => { dispatch(loadingAction(data)) }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(VerMovimientoInventario);

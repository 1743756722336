import React from "react";
import {
    Card, Col, Container,
    Row
} from "react-bootstrap";
import { Link } from 'react-router-dom';

import { connect } from 'react-redux';
import { loadingAction, seleccionarMesaAction } from "../../redux/actions";
import { productosService } from "../../services";


function MovimientosInventarioScreen(props) {

    const [movimientos, setMovimientos] = React.useState(null);

    React.useEffect(() => {
        getMovimientos();
    }, []);

    const getMovimientos = async () => {
        const result = await productosService.ObtenerMovimientos();
        console.log("mostrando result", result)
        setMovimientos(result);
    }

    return (
        <Container fluid>
            <Row className="p-4">
                <Col lg={4}>
                    <h2>Movimientos del inventario</h2>
                </Col>
                <Col lg={8} className="text-end">
                    <Link className="btn btn-primary border-0" to={`/inventario/movimientos/nuevo`} >Nuevo Movimiento</Link>
                </Col>
            </Row>
            <Row>
                <Col lg={12}>
                    <Card>
                        <Card.Body>
                            <div className="table-responsive">
                                <table className="table table-striped table-hover">
                                    <thead>
                                        <tr>
                                            <th>Operacion</th>
                                            <th>Categoria</th>
                                            <th>Cantidad Items</th>
                                            <th>Fecha</th>
                                            <th>Opciones</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {movimientos && movimientos.map((data, index) => {
                                            return (
                                                <tr key={index}>
                                                    <td>{data.operacion.nombre}</td>
                                                    <td>{data.categoria}</td>
                                                    <td>{data.movimientosItems.length}</td>
                                                    <td>{new Date(data.fechaCreacion).toLocaleDateString('es')}</td>
                                                    <td>
                                                        <Link className="btn btn-secondary border-0 btn-xs" to={`/inventario/movimientos/ver/${data.id}`} ><i className="fa fa-pencil"></i></Link>
                                                    </td>
                                                </tr>
                                            )
                                        })}
                                    </tbody>
                                </table>

                            </div>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Container>

    );

}

const mapStateToProps = (state) => {
    return {
        pos: state.pos,
        user: state.user,
        mesaSeleccionada: state.pos.mesaSeleccionada
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        seleccionarMesaActionProps: (data) => { dispatch(seleccionarMesaAction(data)) },
        loadingActionProps: (data) => { dispatch(loadingAction(data)) }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(MovimientosInventarioScreen);
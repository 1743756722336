import React from 'react';
import { connect } from 'react-redux';
import { abrirCajaAction, loadingAction } from "../../redux/actions";
import { posService } from '../../services';
function PreFacturaComponent(props) {


  const { mesaAtencion, subTotal, total, grandTotal, valorPropina, tipoPropina, user } = props;

  const [configurationData, setConfigurationData] = React.useState(null);

  const print = (areaID) => {
    var printContent = document.getElementById(areaID);
    var WinPrint = window.open('', '', '');
    WinPrint.document.write(printContent.innerHTML);
    WinPrint.document.close();
    WinPrint.focus();
    WinPrint.print();
    WinPrint.close();
  }

  React.useEffect(() => {
    getConfigurationData();
  }, [])

  const getConfigurationData = async () => {
    const result = await posService.ObtenerEstablecimientos(user.data.TenantId);
    setConfigurationData(result);
  }

  console.log("mostrando props", props);


  return (
    <>
      {mesaAtencion &&
        <>
          <div className="container" >
            <div className="row" id="printable">
              <div className="col-lg-12">

                <div className="text-center">
                  <h3>{configurationData && configurationData.alias}</h3>
                  <span>{configurationData && configurationData.nombre}</span><br />
                  <span><b>Nit:</b> {configurationData && configurationData.nit}</span><br />
                  <span><b>Correo:</b> {configurationData && configurationData.correo}</span><br />
                  <span><b>Telefono:</b> {configurationData && configurationData.telefono}</span><br />
                  <span><b>Direccion:</b> {configurationData && configurationData.direccion}</span><br />
                  <span><b>Regimen:</b> {configurationData && configurationData.regimen}</span><br />
                  <span>{configurationData && configurationData.ciudad} - {configurationData && configurationData.pais}</span>

                </div>
                <hr />
                <span><b>Fecha:</b> {new Date(mesaAtencion.fechaCreacion).toLocaleDateString('es-Es')} {new Date(mesaAtencion.fechaCreacion).toLocaleTimeString('es-Es')}</span><br />
                <span><b>Mesa:</b> {mesaAtencion?.mesa?.nombre}</span><br />
                <span><b>Mesero:</b> {mesaAtencion?.mesero?.nombreCompleto}</span><br />
                <hr />
                <span><b>Cliente:</b>{mesaAtencion?.cliente?.tipoDocumento} {mesaAtencion?.cliente?.numeroDocumento} {mesaAtencion?.cliente?.nombreCompleto}</span><br />
                <span><b>Correo:</b> {mesaAtencion?.cliente?.email}</span><br />
                <span><b>Telefono:</b> {mesaAtencion?.cliente?.telefono}</span><br />
                <hr />
                <table className="table">
                  <thead>
                    <tr>
                      <th>Producto</th>
                      <th>Cantidad</th>
                      <th>Subtotal</th>
                    </tr>
                  </thead>
                  <tbody>
                    {mesaAtencion && mesaAtencion.mesaAtencionItems.map((data, index) => {
                      return (
                        <tr key={index}>
                          <td>{data.producto.nombre}</td>
                          <td>{data.cantidad}</td>
                          <td>{data.producto.precioVenta.toLocaleString('es-Es')}</td>
                        </tr>
                      )
                    })}
                  </tbody>
                </table>
                <table className="table">
                  <tbody>
                    <tr>
                      <th>Total Items</th>
                      <th>{mesaAtencion.mesaAtencionItems.length}</th>
                    </tr>
                    <tr>
                      <th>Subtotal</th>
                      <th>{subTotal.toLocaleString('es-Es')}</th>
                    </tr>
                    <tr>
                      <th>Propina {tipoPropina == 'porcentaje' ? 'En %' : 'En cantidad fija'}</th>
                      <th>{valorPropina.toLocaleString('es-Es')}</th>
                    </tr>
                    <tr>
                      <th>Total a pagar</th>
                      <th>{grandTotal.toLocaleString('es-Es')}</th>
                    </tr>



                  </tbody>
                </table>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-12">
                <button type="button" className="btn btn-primary" onClick={() => print('printable')}>Imprimir</button>
              </div>
            </div>
          </div>
        </>
      }
    </>

  )
}

const mapStateToProps = (state) => {
  return {
    pos: state.pos,
    user: state.user,
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    abrirCajaActionProps: (data) => { dispatch(abrirCajaAction(data)) },
    loadingActionProps: (data) => { dispatch(loadingAction(data)) }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(PreFacturaComponent);
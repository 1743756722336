import { fetch } from '../../utils/fetch';

const Login = async(payload) => {
    
  try {
      const { data } = await fetch('auth/login', 'post', payload)
      const {token} = data;
      return token
    } catch (e) {
      return Promise.reject(e)
    }
}

const CheckTenantName = async(payload) => {
    
  try {
      const { data } = await fetch('auth/tenant/'+payload, 'get', payload)
      return data
    } catch (e) {
      return Promise.reject(e)
    }
}

const signup = async(payload) => {
    try {
        const { data } = await fetch('auth/signup', 'post', payload)
        return data
      } catch (e) {
        return Promise.reject(e)
      }
  }

export const loginService = {
    Login,
    signup,
    CheckTenantName
}
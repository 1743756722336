import React from 'react';
import { connect } from 'react-redux';
import { abrirCajaAction, loadingAction } from "../../redux/actions";
function ComandaComponent(props) {

    const { mesaAtencion } = props;

    const print = (areaID) => {
        var printContent = document.getElementById(areaID);
        var WinPrint = window.open('', '', '');
        WinPrint.document.write(printContent.innerHTML);
        WinPrint.document.close();
        WinPrint.focus();
        WinPrint.print();
        WinPrint.close();
    }


    return (
        <div className="container" >
            <div className="row" id="printable">
                <div className="col-lg-12">
                    <h3>Comanda</h3>
                    <span><b>Fecha:</b> {new Date(mesaAtencion.fechaCreacion).toLocaleDateString('es-Es')} {new Date(mesaAtencion.fechaCreacion).toLocaleTimeString('es-Es')}</span><br />
                    <span><b>Mesa:</b> {mesaAtencion?.mesa?.nombre}</span><br />
                    <span><b>Mesero:</b> {mesaAtencion?.mesero?.nombreCompleto}</span><br />
                    <hr />
                    <table className="table">
                        <thead>
                            <tr>
                                <th>Producto</th>
                                <th>Cantidad</th>
                            </tr>
                        </thead>
                        <tbody>
                            {mesaAtencion && mesaAtencion.mesaAtencionItems.map((data, index) => {
                                return (
                                    <tr key={index}>
                                        <td>{data.producto.nombre}</td>
                                        <td>{data.cantidad}</td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </table>
                </div>
            </div>
            <div className="row">
                <div className="col-lg-12">
                    <button type="button" className="btn btn-primary" onClick={() => print('printable')}>Imprimir</button>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        pos: state.pos,
        user: state.user,
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        abrirCajaActionProps: (data) => { dispatch(abrirCajaAction(data)) },
        loadingActionProps: (data) => { dispatch(loadingAction(data)) }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ComandaComponent);
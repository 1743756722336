const types = {
    login: '[Login del usuario]',
    logout: '[Logout del usuario]',
    isLoading: '[Loading]',
    abrirCaja: '[Pos] Abrir Caja',
    cerrarCaja: '[Pos] Cerrar Caja',
    seleccionarMesa: '[Pos] Seleccionar mesa',
    collapseMenu: '[UI] Collapse Menu',
    selectMenu: '[UI] Selecccionar Menu',
    }
    
    export default types
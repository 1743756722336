import React, {useState} from 'react';
import {Modal, Button} from 'react-bootstrap'

function ModalComponent({isShowing,setIsShowing, onSave, title, children, showSave}) {

    
    return (
      <>
        <Modal show={isShowing} onHide={()=>setIsShowing(false)}>
          <Modal.Header closeButton>
            <Modal.Title>{title}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
              {children}
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={()=>setIsShowing(false)}>
              Cerrar
            </Button>
            {showSave &&
            <Button variant="primary" onClick={()=>onSave()}>
            Guardar
          </Button>
            }
            
          </Modal.Footer>
        </Modal>
      </>
    );
  }

  export default ModalComponent;
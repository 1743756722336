import React from 'react';
import { connect } from 'react-redux';
import { abrirCajaAction, loadingAction } from "../../redux/actions";
import { posService } from '../../services';
function VerCierreCajaComponent(props) {

  const { cajaId } = props;

  const [caja, setCaja] = React.useState(null);

  const print = (areaID) => {
    var printContent = document.getElementById(areaID);
    var WinPrint = window.open('', '', '');
    WinPrint.document.write(printContent.innerHTML);
    WinPrint.document.close();
    WinPrint.focus();
    WinPrint.print();
    WinPrint.close();
  }

  React.useEffect(() => {
    if (cajaId) {
      getCaja();
    }

  }, [])

  const getCaja = async () => {
    const result = await posService.ObtenerCaja(cajaId);
    setCaja(result);
  }


  return (
    <>
      {caja &&
        <>
          <div className="container" >
            <div className="row" id="printable">
              <div className="col-lg-12">

                <div className="text-center">
                  <h2>Cierre de Caja</h2>
                </div>
                <div className="text-left">
                  <b>Fecha de Apertura:</b> {new Date(caja.fechaApertura).toLocaleString('es-Es')}<br />
                  <b>Fecha de Cierre:</b> {new Date(caja.fechaCierre).toLocaleString('es-Es')}<br />
                  <b>Abierto Por:</b> {caja?.usuarioAbre?.nombreCompleto}<br />
                  <b>Cerrado Por:</b> {caja?.usuarioCierra?.nombreCompleto}<br />
                  <b>Base Efectivo:</b> {caja.dineroBase.toLocaleString('es-Es')}<br />
                  <b>Total Vendido:</b> {caja.totalVendido.toLocaleString('es-Es')}<br />
                  <b>Total Recaudado:</b> {(parseFloat(caja.totalVendido) + parseFloat(caja.totalPropinas)).toLocaleString('es-Es')}
                </div>
                <hr />
                <div className="text-center">
                  <span className="text-center" style={{ fontSize: '9px', textAlign: 'center' }}>Software MiPosDigital - https://cloudsoft.site - Hora del reporte: {new Date().toLocaleString()} </span>
                </div>
                <table className="table">
                  <thead>
                    <tr>
                      <th>Efectivo</th>
                      <th>{caja.efectivo.toLocaleString('es-Es')}</th>
                    </tr>
                    <tr>
                      <th>Tarjetas</th>
                      <th>{caja.tarjetas.toLocaleString('es-Es')}</th>
                    </tr>
                    <tr>
                      <th>Total Propinas</th>
                      <th>{caja.totalPropinas.toLocaleString('es-Es')}</th>
                    </tr>
                    <tr>
                      <th>Propinas Efectivo</th>
                      <th>{caja.propinasEfectivo.toLocaleString('es-Es')}</th>
                    </tr>
                    <tr>
                      <th>Propinas Tarjetas</th>
                      <th>{caja.propinasTarjetas.toLocaleString('es-Es')}</th>
                    </tr>
                  </thead>
                </table>
                <hr />
                <div className="text-center">
                  <span className="text-center" style={{ fontSize: '9px', textAlign: 'center' }}>Software MiPosDigital - https://cloudsoft.site - Hora del reporte: {new Date().toLocaleString()} </span>
                </div>
                <h5>Facturas</h5>
                <table className="table">
                  <tr>
                    <th>No Factura</th>
                    <th>Total</th>
                    <th>Propina</th>
                    <th>Grand Total</th>
                  </tr>
                  {caja?.facturas && caja?.facturas?.map((data, index) => {
                    return (
                      <tr>
                        <td>{data.noFactura}</td>
                        <td>{data.total.toLocaleString('es')}</td>
                        <td>{(data.grandTotal - data.total).toLocaleString('es')}</td>
                        <td>{data.grandTotal.toLocaleString('es')}</td>
                      </tr>
                    )
                  })}
                </table>
                <hr />
                <div className="text-center">
                  <span className="text-center" style={{ fontSize: '9px', textAlign: 'center' }}>Software MiPosDigital - https://cloudsoft.site - Hora del reporte: {new Date().toLocaleString()} </span>
                </div>
                <h5>Productos</h5>
                <table className="table">
                  <tr>
                    <th>Nombre</th>
                    <th>Cantidad</th>
                    <th>Precio</th>
                  </tr>
                  {caja?.facturas && caja?.facturas?.map((data, index) => {
                    return (
                      <>

                        {data.facturaItems.map((productData, ix) => {
                          return (
                            <tr>
                              <td>{productData.producto.nombre}</td>
                              <td>{productData.cantidad}</td>
                              <td>{productData.producto.precioVenta.toLocaleString('es')}</td>
                            </tr>
                          )
                        })}

                      </>
                    )
                  })}
                </table>
                <div className="text-center">
                  <span className="text-center" style={{ fontSize: '9px', textAlign: 'center' }}>Software MiPosDigital - https://cloudsoft.site - Hora del reporte: {new Date().toLocaleString()} </span>
                </div>

              </div>
            </div>
            <div className="row">
              <div className="col-lg-12">
                <button type="button" className="btn btn-primary" onClick={() => print('printable')}>Imprimir</button>
              </div>
            </div>
          </div>
        </>
      }
    </>

  )
}

const mapStateToProps = (state) => {
  return {
    pos: state.pos,
    user: state.user,
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    abrirCajaActionProps: (data) => { dispatch(abrirCajaAction(data)) },
    loadingActionProps: (data) => { dispatch(loadingAction(data)) }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(VerCierreCajaComponent);
import { useFormik } from 'formik';
import React from 'react';
import { connect } from 'react-redux';
import Swal from 'sweetalert2';
import { loadingAction } from '../../redux/actions';
import { posService } from '../../services';
function ConfiguracionScreen(props) {

    const { user, loadingActionProps } = props;

    const formik = useFormik({
        initialValues: {
            "nombre": "",
            "alias": "",
            "nit": "",
            "logo": "",
            "direccion": "",
            "telefono": "",
            "correo": "",
            "ciudad": "",
            "pais": "",
            "regimen": "",
            "resolucion": "",
            "prefijo": "",
        },
        enableReinitialize: true,
        onSubmit: values => {
            submit(values);
        }
    })


    React.useEffect(() => {

        getConfigurationData();
    }, [])

    const getConfigurationData = async () => {
        loadingActionProps(true);
        try {
            const result = await posService.ObtenerEstablecimientos(user.data.TenantId);
            formik.setValues(result);
            loadingActionProps(false);
        } catch (error) {
            loadingActionProps(false);
        }
    }

    const submit = async (values) => {
        loadingActionProps(true);
        try {
            const result = await posService.ActualizarEstablecimientos(user.data.TenantId, values);
            formik.setValues(result);
            Swal.fire({ icon: 'success', title: 'Éxito: ', text: 'Actualizado con éxito' })
            loadingActionProps(false);
        } catch (error) {
            loadingActionProps(false);
        }
    }



    return (
        <>
            <div className="container">
                <form onSubmit={formik.handleSubmit}>
                    <div className="row">
                        <div className="col-lg-4">
                            <div className="mb-3">
                                <label>Nombre</label>
                                <input type="text" className="form-control" name="nombre" onChange={formik.handleChange} defaultValue={formik.values.nombre} />
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div className="mb-3">
                                <label>Alias</label>
                                <input type="text" className="form-control" name="alias" onChange={formik.handleChange} defaultValue={formik.values.alias} />
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div className="mb-3">
                                <label>Nit</label>
                                <input type="text" className="form-control" name="nit" onChange={formik.handleChange} defaultValue={formik.values.nit} />
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-4">
                            <div className="mb-3">
                                <label>Direccion</label>
                                <input type="text" className="form-control" name="direccion" onChange={formik.handleChange} defaultValue={formik.values.direccion} />
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div className="mb-3">
                                <label>Telefono</label>
                                <input type="text" className="form-control" name="telefono" onChange={formik.handleChange} defaultValue={formik.values.telefono} />
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div className="mb-3">
                                <label>Correo</label>
                                <input type="text" className="form-control" name="correo" onChange={formik.handleChange} defaultValue={formik.values.correo} />
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-4">
                            <div className="mb-3">
                                <label>Ciudad</label>
                                <input type="text" className="form-control" name="ciudad" onChange={formik.handleChange} defaultValue={formik.values.ciudad} />
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div className="mb-3">
                                <label>Pais</label>
                                <input type="text" className="form-control" name="pais" readOnly defaultValue={formik.values.pais} />
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div className="mb-3">
                                <label>Regimen</label>
                                <input type="text" className="form-control" name="regimen" onChange={formik.handleChange} defaultValue={formik.values.regimen} />
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-6">
                            <div className="mb-3">
                                <label>Resolucion</label>
                                <input type="text" className="form-control" name="resolucion" onChange={formik.handleChange} defaultValue={formik.values.resolucion} />
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="mb-3">
                                <label>Prefijo</label>
                                <input type="text" className="form-control" name="prefijo" onChange={formik.handleChange} defaultValue={formik.values.prefijo} />
                            </div>
                        </div>

                    </div>

                    <div className="row">
                        <div className="col">
                            <button type="submit" className="btn btn-primary border-0">Guardar Cambios</button>
                        </div>
                    </div>
                </form>
            </div>
        </>
    )

}

const mapStateToProps = (state) => {
    return {
        pos: state.pos,
        user: state.user,
        mesaSeleccionada: state.pos.mesaSeleccionada
    }
};


const mapDispatchToProps = (dispatch) => {
    return {
        loadingActionProps: (data) => { dispatch(loadingAction(data)) }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ConfiguracionScreen);
import { useFormik } from 'formik';
import React from 'react';
import { connect } from 'react-redux';
import { abrirCajaAction, loadingAction } from "../../redux/actions";
import { posService } from '../../services';
function AbrirCajaComponent(props) {

    const {pos, user, abrirCajaActionProps,loading,  loadingActionProps} = props;

    const formik = useFormik({

        initialValues: {
          dineroBase: '',
        },
        onSubmit: values => submit(values),
      });

    const submit = async (payload) => {
        loadingActionProps(true)
        console.log("mostrando payload",payload);
        const params = {
            dineroBase: payload.dineroBase,
            usuarioAbreId: user.data.UsuarioID,
        }
        const result = await posService.AbrirCaja(params);
        console.log('mostrando result', result)
        loadingActionProps(false)
        abrirCajaActionProps({cajaAbierta: true, cajaId: result.id});
    }
    return (
        <form onSubmit={formik.handleSubmit}>
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="form-group">
                            <input type="number" className="form-control" name="dineroBase" placeholder='Dinero Base' value={formik.values.dineroBase} onChange={formik.handleChange}></input>
                        </div>
                    </div>
                    <div className="col-lg-12 my-3">
                        <button type="submit" disabled={loading} className="btn btn-success">{loading ? 'Abriendo Caja...' : 'Abrir Caja'}</button>
                    </div>
                </div>
            </div>
        </form>
    )
}

const mapStateToProps= (state) =>{
    return {
        pos: state.pos,
        user: state.user,
        loading: state.loading
    }
  };
  
  const mapDispatchToProps= (dispatch) =>{
    return {
      abrirCajaActionProps: (data) => {dispatch(abrirCajaAction(data))},
      loadingActionProps: (data)=>{dispatch(loadingAction(data))}
  }
  }
  
  export default connect(mapStateToProps,mapDispatchToProps)(AbrirCajaComponent);

import { fetch } from '../../utils/fetch'

const GetZonas = async () => {

    try {
        const { data } = await fetch('zona', 'get')
        return data
    } catch (e) {
        return Promise.reject(e)
    }
}

const GetZonasById = async (id) => {

    try {
        const { data } = await fetch('zona/' + id, 'get')
        return data
    } catch (e) {
        return Promise.reject(e)
    }
}

const CreateZonas = async (payload) => {

    try {
        const { data } = await fetch('zona', 'post', payload)
        return data
    } catch (e) {
        return Promise.reject(e)
    }
}

const UpdateZonas = async (id, payload) => {

    try {
        const { data } = await fetch('zona/' + id, 'put', payload)
        return data
    } catch (e) {
        return Promise.reject(e)
    }
}

export const zonasService = {
    GetZonas,
    GetZonasById,
    CreateZonas,
    UpdateZonas
}
import types from '../types';
import jwt_decode from "jwt-decode";

export const uiMenuCollapseAction = (data) => {
    return{
        type: types.collapseMenu
    }
}

export const uiSelectMenuAction = (data) => {
    return{
        type: types.selectMenu,
        payload: data
    }
}

export const seleccionarMesaAction = (data) => {

    return{
        type: types.seleccionarMesa,
        payload: data
    }

}

export const abrirCajaAction = (data) => {

    return{
        type: types.abrirCaja,
        payload: data
    }

}

export const cerrarCajaAction = (data) => {

    return{
        type: types.cerrarCaja,
        payload: data
    }

}

export const loginAction = (token) => {

    return{
        type: types.login,
        payload: token
    }

}

export const logoutAction = (token) => {

    return{
        type: types.logout,
        payload: null
    }

}

export const loadingAction = (data) => {
    return{
        type: types.isLoading,
        payload: data
    }
}